import {useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {Button, Modal} from 'react-bootstrap'

import MembersList from './MembersList'
import {useGetTeamMembers, useInviteTeamMember} from '../../queries/userTeam'

function TeamMembers() {
  const [showMemberModal, setShowMemberModal] = useState(false)
  const {data: members} = useGetTeamMembers()
  const {mutateAsync: inviteMember} = useInviteTeamMember()

  const handleClose = () => setShowMemberModal(false)
  const handleShowMemberModal = () => setShowMemberModal(true)

  const handleSubmit = async (values: any, {resetForm}: any) => {
    try {
      await inviteMember(values)
      handleClose()
      resetForm()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='w-100 mt-4 ml20'>
      <div className='d-flex flex-row justify-content-between align-items-center'>
        <div className='d-flex flex-row gap-3 align-items-center'>
          <i className='fa-solid fa-people-group text-black fs-1'></i>
          <span className='fs-3 fw-bold'>Team Members</span>
        </div>

        {members?.length > 0 && (
          <div>
            <Button variant='primary' onClick={handleShowMemberModal}>
              Add New Member
            </Button>
          </div>
        )}
      </div>

      {members?.length === 0 ? (
        <div className='border border-primary d-flex h-45 flex-column mt-6 p-9 gap-8 justify-content-center align-items-center text-center'>
          <i className='bi bi-people-fill text-primary' style={{fontSize: '35px'}}></i>
          <h2>You Have Not Invited Anyone Yet</h2>
          <Button variant='primary' onClick={handleShowMemberModal}>
            Add New Member
          </Button>
        </div>
      ) : (
        <div className='mt-5'>
          <MembersList members={members} />
        </div>
      )}

      <Modal show={showMemberModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{email: ''}}
            validationSchema={Yup.object({
              email: Yup.string().email('Invalid email address').required('Email is required'),
            })}
            onSubmit={handleSubmit}
          >
            {({errors, touched, isSubmitting}) => (
              <Form>
                <div className='form-group mb-3'>
                  <label htmlFor='' className='fw-bold mb-3'>
                    Enter email Address to Invite Member
                  </label>
                  <Field
                    type='email'
                    name='email'
                    className={`form-control ${
                      errors.email && touched.email ? 'border-danger' : ''
                    }`}
                    placeholder='Member’s email...'
                  />
                  <ErrorMessage name='email' component='div' className='text-danger mt-1' />
                </div>

                <Modal.Footer className='p-0'>
                  <Button variant='secondary' onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant='primary' type='submit' disabled={isSubmitting}>
                    Add Member
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default TeamMembers

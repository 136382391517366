import React, {useEffect, useState, useRef, useCallback, Fragment} from 'react'
import {Badge, Spinner} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'

import {useGetUserChats, useDeleteUserChat} from '../../queries/aiChat'
import {ConfirmationModal} from '../../modules/common/ConfirmationModal'
import CustomDropdown from '../../modules/common/CustomDropDown'

import './GovChat.scss'

function UserChats({setShow}: {setShow: (show: boolean) => void}) {
  const navigate = useNavigate()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [chatToDelete, setChatToDelete] = useState(null)
  const routeParams = useParams()
  const {chatId = ''} = routeParams

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
    setChatToDelete(null)
  }

  const {data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch} = useGetUserChats()
  const {mutateAsync: deleteUserChat} = useDeleteUserChat()

  const handleDeleteClick = async () => {
    if (chatToDelete) {
      await deleteUserChat(chatToDelete)
      handleCloseDeleteModal()
      if (chatToDelete === chatId) {
        navigate('/govchat')
      }
    }
  }

  const handleChatClick = (chatId: string) => {
    navigate(`/govchat/${chatId}`)
    setShow(false)
  }

  useEffect(() => {
    if (chatId) {
      refetch()
    }
  }, [chatId])

  const observer: any = useRef()
  const lastChatElementRef = useCallback(
    (node: any) => {
      if (isFetchingNextPage) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage()
        }
      })
      if (node) observer.current.observe(node)
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  )

  return (
    <Fragment>
      <div className='mr15 align-items-center' style={{height: '85%'}}>
        <div className='d-flex user-chats-header align-items-center mb-5'>
          <span className='fs-1 fw-bold mr10'>GOVCHAT</span>
          <Badge bg='success'>
            <span className='text-white'>BETA</span>
          </Badge>
        </div>
        <div
          className='d-flex d-flex align-items-center justify-content-between btn btn-primary text-white'
          onClick={() => {
            navigate('/govchat')
            setShow(false)
          }}
        >
          <div className='align-items-center'>
            <span className='fw-bold fs-5'>New Chat</span>
          </div>
          <div>
            <i className='fa-solid fa-pen-to-square fs-4'></i>
          </div>
        </div>
        <div className='mb-8 mt-2 overflow-y-scroll' style={{height: '60vh'}}>
          {data?.pages?.map((page, pageIndex) => (
            <React.Fragment key={pageIndex}>
              {page.chats?.map((chat: any, chatIndex: number) => {
                if (pageIndex === data.pages.length - 1 && chatIndex === page.chats.length - 1) {
                  return (
                    <div
                      ref={lastChatElementRef}
                      key={chat.id}
                      className='p-3 rounded align-items-end d-flex justify-content-between cursor-pointer mt-4 card-hover-dropdown'
                      style={{border: '1px solid #D6DBDF'}}
                      onClick={() => handleChatClick(chat.uuid)}
                    >
                      <span className='fs-6'>
                        {chat?.chatTitle?.length > 22
                          ? chat?.chatTitle?.slice(0, 22) + '...'
                          : chat?.chatTitle}
                      </span>
                      <CustomDropdown
                        elementId={chat.id}
                        options={[
                          {
                            label: 'Delete Chat',
                            handler: () => {
                              setChatToDelete(chat.uuid)
                              setShowDeleteModal(true)
                            },
                          },
                        ]}
                      />
                      {showDeleteModal && chatToDelete === chat.uuid && (
                        <ConfirmationModal
                          showModal={showDeleteModal}
                          onHideModal={handleCloseDeleteModal}
                          disableBtns={false}
                          onConfirm={handleDeleteClick}
                          onCancel={handleCloseDeleteModal}
                        />
                      )}
                    </div>
                  )
                } else {
                  return (
                    <div
                      key={chat.id}
                      className='p-3 rounded align-items-end d-flex justify-content-between cursor-pointer mt-4 card-hover-dropdown'
                      style={{border: '1px solid #D6DBDF'}}
                      onClick={() => handleChatClick(chat.uuid)}
                    >
                      <span className='fs-6'>
                        {chat?.chatTitle?.length > 22
                          ? chat?.chatTitle?.slice(0, 22) + '...'
                          : chat?.chatTitle}
                      </span>
                      <CustomDropdown
                        elementId={chat.id}
                        options={[
                          {
                            label: 'Delete Chat',
                            handler: () => {
                              setChatToDelete(chat.uuid)
                              setShowDeleteModal(true)
                            },
                          },
                        ]}
                      />
                      {showDeleteModal && chatToDelete === chat.uuid && (
                        <ConfirmationModal
                          showModal={showDeleteModal}
                          onHideModal={handleCloseDeleteModal}
                          disableBtns={false}
                          onConfirm={handleDeleteClick}
                          onCancel={handleCloseDeleteModal}
                        />
                      )}
                    </div>
                  )
                }
              })}
            </React.Fragment>
          ))}
          {isFetchingNextPage && <Spinner animation='border' />}
        </div>
      </div>
    </Fragment>
  )
}

export default UserChats

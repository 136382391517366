import {Droppable} from 'react-beautiful-dnd'
import {useSearchParams} from 'react-router-dom'

import useQueryParam from '../../../hooks/useQueryParam'

const PipelineStatusCard = ({isDraggingCard}: {isDraggingCard: boolean}) => {
  const {addQueryParam, removeQueryParam} = useQueryParam()
  const [searchParams] = useSearchParams()
  const currentStatus = searchParams.get('status')

  const applyStatusFilter = (status: 'submitted' | 'won' | 'lost' | 'inProgress') => {
    if (currentStatus === status) {
      removeQueryParam('status')
    } else {
      addQueryParam('status', status)
    }
  }

  return (
    <div
      className={`mb-5 d-flex ${isDraggingCard ? '' : 'visually-hidden'}`}
      style={{position: 'absolute', bottom: '10px', width: '800px', zIndex: 10}}
    >
      <Droppable key='submitted' droppableId='submitted' type='card'>
        {(provided, snapshot) => (
          <div
            className={`min-w250 status-drop-zone d-flex max-w250 mx-1 rounded py-3 px-2 ${
              snapshot.isDraggingOver || currentStatus === 'submitted'
                ? 'bg-success bg-opacity-25'
                : 'bg-secondary'
            }`}
            ref={provided.innerRef}
            {...provided.droppableProps}
            onClick={() => applyStatusFilter('submitted')}
            style={{maxHeight: '50px'}}
          >
            <h4>Submitted</h4>
            {currentStatus === 'submitted' && <i className='fa-solid fa-filter'></i>}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <Droppable key='won' droppableId='won' type='card'>
        {(provided, snapshot) => (
          <div
            className={`min-w250 status-drop-zone d-flex max-w250 mx-1 rounded py-3 px-2 ${
              snapshot.isDraggingOver || currentStatus === 'won'
                ? 'bg-success bg-opacity-25'
                : 'bg-secondary'
            }`}
            ref={provided.innerRef}
            {...provided.droppableProps}
            onClick={() => applyStatusFilter('won')}
            style={{maxHeight: '50px'}}
          >
            <h4>Won</h4>
            {currentStatus === 'won' && <i className='fa-solid fa-filter'></i>}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <Droppable key='lost' droppableId='lost' type='card'>
        {(provided, snapshot) => (
          <div
            className={`min-w250 status-drop-zone d-flex max-w250 mx-1 rounded py-3 px-2 ${
              snapshot.isDraggingOver || currentStatus === 'lost'
                ? 'bg-success bg-opacity-25'
                : 'bg-secondary'
            }`}
            ref={provided.innerRef}
            {...provided.droppableProps}
            onClick={() => applyStatusFilter('lost')}
            style={{maxHeight: '50px'}}
          >
            <h4>Lost</h4>
            {currentStatus === 'lost' && <i className='fa-solid fa-filter'></i>}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

export default PipelineStatusCard

/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {Spinner} from 'react-bootstrap'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'

import {useGetAllPipelines, useUpdatePipelineOrder} from '../../../queries/pipelines'
import GenericModal from '../../../modules/common/Modal'
import PipeLineForm from './PipeLineForm'
import PipelineItem from './PipelineItem'
import {useAuth} from '../../../modules/auth'

const UserPipelines = ({pipelineNotificationsResult}: {pipelineNotificationsResult: any}) => {
  const [showPipeLineModal, setShowPipeLineModal] = useState(false)
  const [reorderedPipelines, setReorderedPipelines] = useState<any>([])
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const {selectedCommunity} = useAuth()
  const pipelineId = searchParams.get('pipelineId')
  const {data, isLoading} = useGetAllPipelines()
  const pipelines = data?.pipelines
  const sharedPipelines = data?.sharedPipelines
  const {mutateAsync: updateOrder} = useUpdatePipelineOrder()

  useEffect(() => {
    if (!pipelineId && pipelines?.length) {
      navigate(`?pipelineId=${pipelines[0].uuid}`)
    }
  }, [pipelineId, pipelines])

  useEffect(() => {
    if (pipelines?.length) {
      setReorderedPipelines(pipelines)
    }
  }, [pipelines])

  const handleDragEndPipelines = async (result: any) => {
    if (!result.destination) {
      return
    }

    const [oldPipelines, _reorderedPipelines] = [
      Array.from(reorderedPipelines),
      Array.from(reorderedPipelines),
    ]
    const [removed] = _reorderedPipelines.splice(result.source.index, 1)
    _reorderedPipelines.splice(result.destination.index, 0, removed)

    setReorderedPipelines(_reorderedPipelines)

    try {
      await updateOrder({
        pipelineId: result.draggableId,
        order: result.destination.index + 1,
      })
    } catch (error) {
      console.error('Error updating pipeline order', error)
      setReorderedPipelines(oldPipelines)
    }
  }

  return (
    <>
      <div
        className='p-5 rounded h-fit mr10'
        style={{backgroundColor: '#F9F9F9', minWidth: '250px'}}
      >
        {!selectedCommunity?.isTeamMember && (
          <>
            <div className='d-flex justify-content-between align-items-baseline mb-2 mt-4'>
              <h4 className='text-center'>My Pipelines</h4>
              <button
                className='border-0 bg-primary px-3 py-1 rounded ms-3'
                onClick={() => setShowPipeLineModal(true)}
              >
                <i className='fa-solid fa-add text-light'></i>
              </button>
            </div>

            <DragDropContext onDragEnd={handleDragEndPipelines}>
              <Droppable droppableId='pipelineList'>
                {(provided) => (
                  <ul
                    className='nav flex-column mt-4'
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {!isLoading && pipelines?.length === 0 && (
                      <p className='fw-bold fs-6 text-center'>No pipelines found.</p>
                    )}
                    {isLoading && (
                      <div className='d-flex justify-content-between'>
                        <Spinner animation='border' role='status'></Spinner>
                      </div>
                    )}
                    {reorderedPipelines?.map((pipeline: any, index: number) => (
                      <Draggable key={pipeline.uuid} draggableId={pipeline.uuid} index={index}>
                        {(provided) => (
                          <li
                            className='nav-item my-1 cursor-pointer'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <PipelineItem
                              pipeline={pipeline}
                              pipelineNotificationsResult={pipelineNotificationsResult}
                            />
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )}

        {selectedCommunity?.isTeamMember && (
          <div className='d-flex justify-content-between align-items-baseline mb-2 mt-4'>
            <h4 className='text-center'>Shared with me</h4>
            <button
              className='border-0 bg-primary px-3 py-1 rounded ms-3'
              onClick={() => setShowPipeLineModal(true)}
            >
              <i className='fa-solid fa-add text-light'></i>
            </button>
          </div>
        )}

        {sharedPipelines?.length > 0 && (
          <>
            {!selectedCommunity?.isTeamMember && (
              <>
                <div className='content-divider mt-3'></div>
                <h4 className='mb-2 mt-4'>Shared with me</h4>
              </>
            )}
            <ul className='nav flex-column mt-4'>
              {sharedPipelines?.map((pipeline: any) => (
                <li className='nav-item my-1 cursor-pointer' key={pipeline.id}>
                  <PipelineItem
                    pipeline={pipeline}
                    isSharedPipeline
                    pipelineNotificationsResult={pipelineNotificationsResult}
                  />
                </li>
              ))}
            </ul>
          </>
        )}

        {showPipeLineModal && (
          <GenericModal
            title={'Add Pipeline'}
            showModal={showPipeLineModal}
            onHideModal={() => setShowPipeLineModal(false)}
            hideFooter={true}
            modalProps={{
              size: 'md',
              centered: true,
            }}
          >
            <PipeLineForm setShowPipeLineModal={setShowPipeLineModal} />
          </GenericModal>
        )}
      </div>
    </>
  )
}

export default UserPipelines

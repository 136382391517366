import {useEffect, useMemo, useState} from 'react'
import {Draggable, Droppable} from 'react-beautiful-dnd'

import CustomDropdown from '../../../modules/common/CustomDropDown'
import PipeLineCard from './PipeLineCard'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import {useDeletePipelineStage} from '../../../queries/pipelines'
import PipeLineStageForm from './PipeLineStageForm'
import {useSearchParams} from 'react-router-dom'

interface PipelineStageProps {
  stage: any
  pipelineId: string
  pipelineNotificationsResult: any
}

const statusMap: any = {
  submitted: 'submitted',
  won: 'won',
  lost: 'lost',
  inProgress: 'inProgress',
  noBid: 'noBid',
}

const PipeLineStage: React.FC<PipelineStageProps> = ({
  stage,
  pipelineId,
  pipelineNotificationsResult,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditStageModal, setShowEditStageModal] = useState(false)
  const [stageCards, setStageCards] = useState([])
  const {mutateAsync: deletePipelineStage} = useDeletePipelineStage()
  const [searchParams] = useSearchParams()
  const filterStatus = searchParams.get('status') || ''

  const editStageOptions = useMemo(
    () => [
      {
        label: (
          <>
            <span className='fa-solid fs-7 fa-pencil text-muted'></span>
            <span className='mx-2 text-muted'>Edit</span>
          </>
        ),
        handler: () => setShowEditStageModal(true),
      },
      {
        label: (
          <>
            <span className='fa-solid fs-7 text-danger fa-trash'></span>
            <span className='mx-2 text-danger'>Archive</span>
          </>
        ),
        handler: () => setShowDeleteModal(true),
      },
    ],
    [stage]
  )

  const handleDeletePipeLine = async () => {
    await deletePipelineStage({pipelineId: pipelineId, stageId: stage.uuid})
    setShowDeleteModal(false)
  }

  useEffect(() => {
    if (filterStatus && statusMap[filterStatus]) {
      const filterCards = stage.pipelineStageCards.filter(
        (card: any) => card.status === statusMap[filterStatus]
      )
      setStageCards(filterCards)
    } else {
      setStageCards(stage.pipelineStageCards || [])
    }
  }, [stage.pipelineStageCards, filterStatus])

  return (
    <div className='card-container bg-white rounded cursor-default'>
      <div className='d-flex flex-row rounded justify-content-between px-4 py-3 min-w250 bg-primary'>
        <h4 className='text-white m-0'>{stage.name}</h4>
        <div>
          <CustomDropdown
            elementId={'pipelineStage'}
            options={editStageOptions}
            className='stage-dropdown'
          />
        </div>
      </div>
      <Droppable key={stage.uuid} droppableId={stage.uuid} type='card'>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps} className='card-list'>
            {stageCards.map((card: any, cardIndex: number) => (
              <Draggable key={card.uuid} draggableId={card.uuid} index={cardIndex}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className='mt-4'
                  >
                    <PipeLineCard
                      card={card}
                      pipelineId={pipelineId}
                      stageId={stage.uuid}
                      pipelineNotificationsResult={pipelineNotificationsResult}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {/* To allow dragging at the end */}
            {<div className='min-h50'></div>}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <div>
        {showEditStageModal && (
          <PipeLineStageForm
            stageValues={stage}
            pipelineId={pipelineId}
            showStageFormModal={showEditStageModal}
            setShowStageFormModal={setShowEditStageModal}
          />
        )}
        {showDeleteModal && (
          <ConfirmationModal
            showModal={showDeleteModal}
            onHideModal={() => setShowDeleteModal(false)}
            onCancel={() => setShowDeleteModal(false)}
            title='Archive Stage'
            subtitle='Are you sure you want to archive this Stage?'
            onConfirm={handleDeletePipeLine}
            disableBtns={false}
          />
        )}
      </div>
    </div>
  )
}

export default PipeLineStage

import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {toast} from 'react-toastify'

import {
  NOTIFICATIONS_QUERY_KEY,
  NOTIFICATIONS_SETTINGS_QUERY_KEY,
  NOTIFICATIONS_COUNT_QUERY_KEY,
  PIPELINE_NOTIFICATIONS_COUNT_QUERY_KEY,
  PIPELINE_NOTIFICATIONS_QUERY_KEY,
} from '../constants/reactQueryKeys'
import * as notificationsApi from '../apis/notifications'
import {parseError} from '../modules/common/utils'

export const useGetUserNotificationSettings = () => {
  return useQuery({
    queryKey: [NOTIFICATIONS_SETTINGS_QUERY_KEY],
    queryFn: () => notificationsApi.getUserNotificationSettings(),
  })
}

export const useGetUserNotifications = () => {
  return useQuery({
    queryKey: [NOTIFICATIONS_QUERY_KEY],
    queryFn: () => notificationsApi.getUserNotifications(),
  })
}

export const useGetUserPipelineNotifications = () => {
  return useQuery({
    queryKey: [PIPELINE_NOTIFICATIONS_QUERY_KEY],
    queryFn: () => notificationsApi.getUserPipeLineNotifications(),
  })
}

export const useGetUnreadNotificationsCount = (isEnabled: boolean) => {
  return useQuery({
    queryKey: [NOTIFICATIONS_COUNT_QUERY_KEY],
    queryFn: () => notificationsApi.getUnreadNotificationsCount(),
    enabled: isEnabled,
    refetchOnReconnect: true,
    refetchInterval: 60000,
  })
}

export const useGetUnreadPipelineNotificationsCount = (isEnabled: boolean) => {
  return useQuery({
    queryKey: [PIPELINE_NOTIFICATIONS_COUNT_QUERY_KEY],
    queryFn: () => notificationsApi.getUnreadPipelineNotificationsCount(),
    enabled: isEnabled,
    refetchOnReconnect: true,
    refetchInterval: 60000,
  })
}

export const useUpdateUserNotificationSettings = () => {
  return useMutation({
    mutationFn: (data: {
      featureType: string
      isAppNotificationEnabled: boolean
      isEmailNotificationEnabled: boolean
    }) => notificationsApi.updateUserNotificationSettings(data),
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useReadUserNotification = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({notificationId}: {notificationId?: number}) =>
      notificationsApi.readUserNotification(notificationId ? [notificationId] : undefined),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTIFICATIONS_COUNT_QUERY_KEY],
      })
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useReadPipelineNotification = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({notificationIds}: {notificationIds: number[]}) =>
      notificationsApi.readUserNotification(notificationIds),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PIPELINE_NOTIFICATIONS_QUERY_KEY],
      })
      queryClient.invalidateQueries({
        queryKey: [PIPELINE_NOTIFICATIONS_COUNT_QUERY_KEY],
      })
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

import React, {useState} from 'react'
import Select from 'react-select'

import {
  useFetchPipelineShare,
  useRevokePipeline,
  useSharePipeline,
} from '../../../queries/pipelines'
import Image from '../../../modules/common/Image'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'

const SharePipeline = ({
  pipeline,
  setSharePipeline,
}: {
  pipeline: any
  setSharePipeline: (show: boolean) => void
}) => {
  const [selectedUsers, setSelectedUsers] = useState<{value: string; label: JSX.Element}[]>([])
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [memberToRemove, setMemberToRemove] = useState<any>(null)
  const {data: usersResult, isFetching} = useFetchPipelineShare(pipeline.uuid)
  const {mutateAsync: sharePipeline, isPending} = useSharePipeline()
  const {mutateAsync: revokePipeline} = useRevokePipeline()
  const communityMembers = usersResult?.communityMembers || []

  const userOptions = communityMembers.map((user: any) => ({
    value: user?.user?.id,
    label: (
      <p className='m-0 p-0'>
        <span>
          {user?.user?.firstName} {user?.user?.lastName}
        </span>
        <span className='ms-1'>({user?.role})</span>
      </p>
    ),
  }))

  const handleShare = async () => {
    try {
      const recipientIds = selectedUsers.map((user) => user.value)
      await sharePipeline({pipelineId: pipeline.uuid, recipientIds})
      setSharePipeline(false)
    } catch (error) {}
  }

  const confirmDelete = (id: number) => {
    setMemberToRemove(id)
    setShowRemoveModal(true)
  }

  const handleRemoveAccess = async () => {
    if (memberToRemove) {
      try {
        await revokePipeline({pipelineId: pipeline.uuid, recipientIds: [memberToRemove]})
        setSharePipeline(false)
      } catch (error) {}
    }
  }

  return (
    <div>
      <Select
        isLoading={isFetching}
        options={userOptions}
        isMulti
        placeholder='Add team members'
        onChange={(selectedOption) =>
          setSelectedUsers(selectedOption as {value: string; label: JSX.Element}[])
        }
      />

      {usersResult?.pipelineShares?.length > 0 && (
        <div className='mt-4'>
          <h5>Shared with:</h5>

          {usersResult?.pipelineShares?.map((member: any) => (
            <div
              key={member?.recipient.id}
              className='mb-2 d-flex justify-content-between align-items-center cursor-default users-list'
            >
              <div className='d-flex'>
                <Image src={member?.recipient?.profilePic} />
                <div className='ms-2'>
                  <p className='m-0 p-0 fw-bold'>
                    {member?.recipient.firstName} {member?.recipient.lastName}
                  </p>
                  <p className='m-0 p-0 text-muted'>{member?.recipient.email}</p>
                </div>
              </div>
              <i
                className='fa-solid fs-7 me-2 text-danger fa-trash cursor-pointer'
                onClick={() => confirmDelete(member?.recipient.id)}
              ></i>
            </div>
          ))}
        </div>
      )}

      <div className='d-end mt-4'>
        <button className='btn btn-primary' onClick={handleShare} disabled={isPending}>
          {isPending ? 'Sharing...' : 'Share'}
        </button>
      </div>

      {showRemoveModal && (
        <ConfirmationModal
          title='Revoke access'
          subtitle='Are you sure you want to revoke the access?'
          showModal={showRemoveModal}
          onHideModal={() => setShowRemoveModal(false)}
          disableBtns={false}
          onConfirm={handleRemoveAccess}
          onCancel={() => setShowRemoveModal(false)}
        />
      )}
    </div>
  )
}

export default SharePipeline

import {Button, Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {Formik, Field, ErrorMessage, Form} from 'formik'
import {useCreatePipelineStage, useUpdatePipelineStage} from '../../../queries/pipelines'

const PipeLineStageForm = ({
  pipelineId,
  showStageFormModal,
  setShowStageFormModal,
  stageValues,
}: {
  pipelineId: string
  stageValues?: any
  showStageFormModal: boolean
  setShowStageFormModal: (value: boolean) => void
}) => {
  const {mutateAsync: createStage} = useCreatePipelineStage()
  const {mutateAsync: updatePipelineStage} = useUpdatePipelineStage()

  return (
    <Modal show={showStageFormModal} onHide={() => setShowStageFormModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>{stageValues ? 'Edit Stage' : 'Add New Stage'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            name: stageValues?.name || '',
          }}
          validationSchema={Yup.object({
            name: Yup.string()
              .min(3, 'Name must be at least 3 characters long')
              .required('Name is required'),
          })}
          onSubmit={async (values, {resetForm}) => {
            try {
              if (!values.name.trim()) {
                return
              }
              if (!stageValues) {
                await createStage({
                  pipelineId,
                  data: values,
                })
              } else {
                await updatePipelineStage({pipelineId, stageId: stageValues.uuid, data: values})
              }
              setShowStageFormModal(false)
              resetForm()
            } catch {}
          }}
        >
          {({values, setFieldValue}) => (
            <Form>
              <label htmlFor='name' className='fs-6 fw-bold'>
                Name:
              </label>
              <Field type='text' name='name' className='form-control' placeholder='Stage Name' />
              <ErrorMessage name='name' component='div' className='text-danger' />

              <div className='d-end my-2'>
                <Button
                  type='button'
                  variant='secondary'
                  className='m-1'
                  onClick={() => setShowStageFormModal(false)}
                >
                  Close
                </Button>
                <Button type='submit' variant='primary' className='m-1'>
                  {stageValues ? 'Update' : 'Add'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default PipeLineStageForm

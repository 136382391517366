import {useAuth} from '../../../modules/auth'

const WelcomeScreen = ({
  onSelectDefaultMessage,
}: {
  onSelectDefaultMessage: (message: string) => void
}) => {
  const {currentUser} = useAuth()

  return (
    <div className='d-flex justify-content-center flex-column align-items-center w-100 h-100'>
      <div className='text-center'>
        <h1 style={{fontSize: '40px'}} className='container-heading'>
          Hello,&nbsp;
          <span className='chat-heading'>
            {currentUser?.firstName} {currentUser?.lastName}
          </span>
        </h1>
        <h4 style={{fontSize: '40px', fontWeight: 1200}} className='container-heading'>
          Unlock Your Success with GovChat
        </h4>
        <h4 style={{color: '#717171'}} className='container-subheading'>
          Your AI-Powered Assistant for Government Contracts
        </h4>
      </div>

      <div className='d-flex mt-9 default-msg-container position-absolute' style={{bottom: '90px'}}>
        <div
          className='default-msg-card cursor-pointer px-2 py-3 rounded m-1 fs-5 position-relative'
          onClick={() => onSelectDefaultMessage('How can I win a contract?')}
        >
          How can I win a contract?
          <div className='mt-9 position-absolute bottom-0' style={{right: '5px'}}>
            <i className='fa-solid fa-flask fs-6'></i>
          </div>
        </div>
        <div
          className='default-msg-card cursor-pointer px-2 py-3 rounded m-1 fs-5 position-relative'
          onClick={() => onSelectDefaultMessage('How can I network with other contractors?')}
        >
          How can I network with other contractors?
          <div className='mt-9 position-absolute bottom-0' style={{right: '5px'}}>
            <i className='fa-solid fa-arrow-right fs-6'></i>
          </div>
        </div>
        <div
          className='default-msg-card default-lg-card cursor-pointer px-2 py-3 rounded m-1 fs-5 position-relative'
          onClick={() => onSelectDefaultMessage('How do I highlight my successful bids?')}
        >
          How do I highlight my successful bids?
          <div className='mt-9 position-absolute bottom-0' style={{right: '5px'}}>
            <i className='fa-solid fa-lightbulb fs-6'></i>
          </div>
        </div>
        <div
          className='default-msg-card default-lg-card cursor-pointer px-2 py-3 rounded m-1 fs-5 position-relative'
          onClick={() => onSelectDefaultMessage('How can I find specific government contracts?')}
        >
          How can I find specific government contracts?
          <div className='mt-9 position-absolute bottom-0' style={{right: '5px'}}>
            <i className='fa-solid fa-compass fs-6'></i>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomeScreen

import {useRef} from 'react'
import clsx from 'clsx'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'

import {useAuth} from '../../../modules/auth'
import useMessages from '../../../hooks/useMessages'

import './HeaderWrapper.css'
import {Card, OverlayTrigger, Tooltip} from 'react-bootstrap'
import Image from '../../../modules/common/Image'

const HeaderMessageMenu = ({unreadMesagesResult}: any) => {
  const chatMenuRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const onChatClick = (userId: any) => {
    if (chatMenuRef.current) {
      chatMenuRef.current.classList.remove('show')
    }
    navigate(`/messages/${userId}`)
  }
  return (
    <>
      <div
        ref={chatMenuRef}
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 notification-card'
        data-kt-menu='true'
      >
        <Card>
          <div className='container d-flex justify-content-between align-items-center py-3'>
            <div>
              <h3>Chats</h3>
            </div>
          </div>
          <Card.Body className='p-0'>
            {unreadMesagesResult.isFetching && (
              <div className='position-absolute bg-custom d-flex justify-content-center align-items-center z-3'>
                <svg className='spinner' viewBox='25 25 50 50'>
                  <circle className='spinner-inner' r='20' cy='50' cx='50'></circle>
                </svg>
              </div>
            )}

            <div className='content-divider'></div>
            <div className='overflow-y-scroll' style={{height: '400px'}}>
              {unreadMesagesResult?.data?.messages &&
                unreadMesagesResult?.data.messages.map((messagesData: any) => (
                  <div
                    key={messagesData.id}
                    onClick={() => onChatClick(messagesData.userId)}
                    className='d-flex align-items-center cursor-pointer border-bottom border-dark-subtle py-4 px-8 container user-card'
                  >
                    <Image
                      className='img-fluid h40 w40 rounded-circle me-3 object-cover object-top'
                      src={messagesData.sender?.absoluteProfilePath}
                      alt='user-img'
                    />

                    <div className='flex-grow-1'>
                      <div className='d-flex align-items-center mb-1'>
                        <p
                          className={`m-0 ${
                            messagesData.status === 'unread'
                              ? 'text-primary fw-bold'
                              : 'text-black fw-bold'
                          }`}
                        >
                          {messagesData.sender?.firstName} {messagesData.sender?.lastName}
                        </p>
                        <p className='fw-bold mx-1 my-0 fs-7 text-muted'>
                          • {moment(messagesData.createdAt).fromNow()}
                        </p>
                      </div>
                      <p
                        className={`m-0 ${
                          messagesData.status === 'unread' ? 'fw-bolder' : 'fw-normal'
                        }`}
                      >
                        {messagesData.message}
                      </p>
                    </div>

                    {messagesData?.status === 'unread' && (
                      <div className='ms-2'>
                        <OverlayTrigger
                          placement='top'
                          overlay={<Tooltip id='tooltip-top'>Mark as read</Tooltip>}
                        >
                          <div
                            style={{width: '12px', height: '12px'}}
                            className='rounded-circle bg-primary cursor-pointer'
                          ></div>
                        </OverlayTrigger>
                      </div>
                    )}
                  </div>
                ))}
              {!unreadMesagesResult.isFetching &&
                (!unreadMesagesResult?.data?.messages ||
                  unreadMesagesResult?.data?.messages?.length === 0) && (
                  <div className='py-6 px-9'>
                    <p>No chats found!</p>
                  </div>
                )}
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

const HeaderMessage = ({btnClass}: any) => {
  const {unreadMesagesResult} = useMessages()
  const userAvatarClass = 'symbol-30px symbol-md-40px'
  const {unreadMessagesCount} = useAuth()

  const onMessageMenuClick = function () {
    unreadMesagesResult.refetch()
  }
  return (
    <div>
      <div
        className={clsx(
          'cursor-pointer symbol',
          userAvatarClass,
          unreadMessagesCount > 0 && 'position-relative'
        )}
        data-kt-menu-trigger='click'
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
        onClick={onMessageMenuClick}
      >
        <i
          style={{color: '#D0D2DA'}}
          className={`bi bi-chat-square-dots-fill mx-2 fs-1 ${btnClass}`}
        >
          {unreadMessagesCount > 0 && (
            <span className='position-absolute mt-3 start-50 ml10 translate-middle badge rounded-circle bg-danger messages-badge'>
              <span className='visually-hidden'>New messages</span>
            </span>
          )}
        </i>
      </div>
      <HeaderMessageMenu unreadMesagesResult={unreadMesagesResult} />
    </div>
  )
}

export default HeaderMessage

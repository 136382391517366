import moment from 'moment'

import {useFetchEvents} from '../../queries/events'
import {useAuth} from '../auth'
import {getTimeUntilEvent} from './utils'

const RecentAnnouncement = () => {
  const {getUserTimezone} = useAuth()
  const startDate = moment().format('YYYY-MM-DD')
  const endDate = moment().add(1, 'day').format('YYYY-MM-DD')
  const {data: upcomingEvent} = useFetchEvents(startDate, endDate, 1)

  const recentEvent = upcomingEvent?.events.find((event: any) => {
    const eventEndMoment = moment.utc(event.endDate).tz(getUserTimezone())
    return moment().isBefore(eventEndMoment)
  })

  const calculateEventTime = recentEvent
    ? getTimeUntilEvent({
        eventStartDate: recentEvent.startDate,
        timezone: getUserTimezone(),
      })
    : null

  return (
    <>
      {recentEvent && (
        <div className='d-flex justify-content-center mt-4 mb-3'>
          <i className='fa-solid fa-calendar-days fs-2 text-black'></i>
          <span className='fs-4 ms-3'>
            <strong>{recentEvent.title}</strong> is happening in {calculateEventTime}
          </span>
        </div>
      )}
    </>
  )
}

export default RecentAnnouncement

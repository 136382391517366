import {getSelectedCommunityId} from '../modules/common/utils'
import apiClient from './apiClient'

export const getUserNotificationSettings = async () => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/notifications/settings`)
  return response.data?.data || []
}

export const getUserNotifications = async () => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/notifications`)
  return response.data?.data || []
}

export const getUserPipeLineNotifications = async () => {
  const response = await apiClient.get(
    `/c/${getSelectedCommunityId()}/notifications?notificationType=pipelines`
  )
  return response.data?.data || []
}

export const getUnreadNotificationsCount = async () => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/notifications/unread`)
  return response.data?.data || []
}

export const getUnreadPipelineNotificationsCount = async () => {
  const response = await apiClient.get(
    `/c/${getSelectedCommunityId()}/notifications/unread?notificationType=pipelines`
  )
  return response.data?.data || []
}

export const updateUserNotificationSettings = async (data: any) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/notifications/settings`,
    data
  )
  return response.data?.data || {}
}

export const readUserNotification = async (notificationIds?: number[]) => {
  const response = await apiClient.put(`/c/${getSelectedCommunityId()}/notifications/read`, {
    notificationIds,
  })
  return response.data?.data || {}
}

import moment from 'moment'
import {Badge, Card, ListGroup} from 'react-bootstrap'

import {useFetchEvents} from '../../queries/events'
import {useAuth} from '../../modules/auth'

function UpcomingEvents() {
  const {getUserTimezone} = useAuth()
  const startDate = moment().format('YYYY-MM-DD')
  const endDate = moment().add(7, 'days').format('YYYY-MM-DD')

  const {data: upcomingEventsResult} = useFetchEvents(startDate, endDate)
  return (
    <div
      className='d-flex justify-content-center mt-4 mb-9 p-5'
      style={{backgroundColor: 'rgb(239,246,255)'}}
    >
      <Card style={{width: '22rem'}} className='bg-transparent'>
        <h3 className='text-primary'>Upcoming Events</h3>

        {upcomingEventsResult?.events?.length < 1 && 'No Events Available'}

        {upcomingEventsResult?.events?.map((event: any) => {
          const eventStartDate = moment.utc(event.startDate).tz(getUserTimezone())
          const eventEndDate = moment.utc(event.endDate).tz(getUserTimezone())
          return (
            <ListGroup variant='flush' key={event.id} className='bg-transparent mt-5'>
              <div>
                <div className='d-flex align-items-start'>
                  <div className='text-center' style={{width: '50px'}}>
                    <Badge bg='secondary' className='text-muted fw-bolder d-flex flex-column p-2'>
                      <h5 className='mb-0'>{eventStartDate.format('DD')}</h5>
                      <small className='text-uppercase'>{eventStartDate.format('MMM')}</small>
                    </Badge>
                  </div>
                  <div className='ms-4'>
                    <div>
                      <p className='text-black fs-7 fw-bold p-0 m-0'>{event.title}</p>
                    </div>
                    <div>
                      <p className='text-muted fw-bold p-0 fs-8'>
                        {eventStartDate.format('hh:mm A')} - {eventEndDate.format('hh:mm A')}{' '}
                        {moment.tz(getUserTimezone()).format('z')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ListGroup>
          )
        })}
      </Card>
    </div>
  )
}

export default UpcomingEvents

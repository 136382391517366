import React from 'react'
import {Link} from 'react-router-dom'

interface UserLevel {
  levelId: number
  levelName: string
  percentage: string
}

interface UserLevelsProps {
  levelDistribution: UserLevel[]
  currentLevel: any
}

const UserLevels: React.FC<UserLevelsProps> = ({levelDistribution, currentLevel}) => {
  return (
    <div className='d-flex flex-row mx-16 user-levels-container justify-content-between w-100'>
      <div className='d-flex flex-column row-gap-7 mx-19 user-levels-container'>
        {levelDistribution?.slice(0, 5).map((level, index) => (
          <div className='d-flex flex-row' key={index}>
            <div>
              <div
                className={`rounded-circle w40 h40 ${
                  level.levelId === currentLevel?.id ? 'bg-success' : 'bg-primary'
                } d-flex align-items-center justify-content-center text-white fs-5 fw-bold`}
              >
                {level.levelId === currentLevel?.id ? (
                  currentLevel.levelNumber
                ) : (
                  <i className='fa-solid fa-lock text-white'></i>
                )}
              </div>
            </div>
            <div className='mx-3'>
              <h3>{level.levelName}</h3>
              <Link to='#' className='text-muted fs-7 fw-bold text-decoration-none'>
                {level.percentage}% of members
              </Link>
            </div>
          </div>
        ))}
      </div>

      <div className='d-flex flex-column row-gap-7'>
        {levelDistribution?.slice(5, 9).map((level, index) => (
          <div className='d-flex flex-row' key={index}>
            <div>
              <div className='rounded-circle w40 h40 bg-primary d-flex align-items-center justify-content-center fs-5 fw-bold'>
                <i className='fa-solid fa-lock text-white'></i>
              </div>
            </div>
            <div className='mx-3'>
              <h3>{level.levelName}</h3>
              <Link to='#' className='text-muted fw-bold text-decoration-none'>
                {level.percentage}% of members
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default UserLevels

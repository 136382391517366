import React, {useState} from 'react'
import {Button, Form as BForm, Modal} from 'react-bootstrap'
import Select from 'react-select'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'

import {useAuth} from '../../../modules/auth'
import InvitesFilter from './InvitesFilter'
import {
  useSendInvite,
  useSendBulkInvites,
  useDeleteInvites,
  useResendInvites,
} from '../../../queries/invites'

interface InviteTableHeaderProps {
  bulkSelection: boolean
  selectedRows: any[]
  globalFilter: string
  handleSearch: any
}

const InviteTableHeader: React.FC<InviteTableHeaderProps> = ({
  bulkSelection,
  selectedRows,
  globalFilter,
  handleSearch,
}) => {
  const {selectedCommunity} = useAuth()
  const sendInviteMutation = useSendInvite()
  const sendBulkInvitesMutation = useSendBulkInvites()
  const deleteInvitesMutation = useDeleteInvites()
  const resendInvitesMutation = useResendInvites()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0])
  }

  const options = [
    {value: 'free_member', label: 'Free Member'},
    {value: 'member', label: 'Paid Member'},
    {value: 'moderator', label: 'Moderator'},
    {value: 'admin', label: 'Community Admin'},
  ]

  const packageOptions = [
    {value: 'basic', label: 'Basic Plan'},
    {value: 'premium', label: 'Premium Plan'},
  ]

  const initialValues = {
    email: '',
    role: '',
    loginExpiresOn: '',
    packageType: 'basic',
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    role: Yup.string().required('User role is required'),
    packageType: Yup.string().required('Package type is required'),
    loginExpiresOn: Yup.string().optional(),
  })

  const downloadTemplateFile = function () {
    window.open(`${process.env.REACT_APP_API_URL}/public/download-invite-csv`)
  }

  const handleSubmit = async (values: any) => {
    try {
      setIsSubmitting(true)
      await sendInviteMutation.mutateAsync({
        ...values,
        communityId: selectedCommunity?.id,
      })
      setShowInviteModal(false)
    } catch (error: any) {
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleSendBulkInvites = async () => {
    if (!selectedFile) {
      return
    }
    try {
      const formData = new FormData()
      formData.append('file', selectedFile)
      formData.append('communityId', selectedCommunity?.id || '')
      setIsSubmitting(true)
      await sendBulkInvitesMutation.mutateAsync(formData)
      setShowImportModal(false)
    } catch {
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleBulkDelete = async () => {
    try {
      setShowDeleteModal(false)
      setIsSubmitting(true)
      await deleteInvitesMutation.mutateAsync(selectedRows)
    } catch {
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleResendInvites = async () => {
    try {
      setIsSubmitting(true)
      await resendInvitesMutation.mutateAsync(selectedRows)
      setIsSubmitting(false)
    } catch {
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      {!bulkSelection && (
        <div className='d-flex justify-content-between mx-8'>
          <div className='d-flex'>
            <BForm.Control
              type='text'
              value={globalFilter || ''}
              onChange={handleSearch}
              placeholder='Search...'
            />
            <InvitesFilter />
          </div>
          <div>
            <Button className='mx-1' variant='primary' onClick={() => setShowInviteModal(true)}>
              Invite
            </Button>
            <Button
              className='mx-1'
              variant='secondary'
              onClick={() => {
                setShowImportModal(true)
              }}
            >
              Import
            </Button>
          </div>
        </div>
      )}

      {bulkSelection && (
        <div className='d-end mx-8 align-items-center'>
          <div className='mx-4 fw-bold'>{selectedRows.length} Selected</div>
          <Button disabled={isSubmitting} variant='primary' onClick={handleResendInvites}>
            Resend Invites
          </Button>

          {selectedCommunity?.permissions.canDeleteCourse && (
            <Button
              disabled={isSubmitting}
              className='mx-4'
              variant='danger'
              onClick={() => setShowDeleteModal(true)}
            >
              Delete Selected
            </Button>
          )}
        </div>
      )}

      {showInviteModal && (
        <Modal show={showInviteModal} onHide={() => setShowInviteModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Invite a User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({isSubmitting}) => (
                <Form>
                  <div>
                    <label htmlFor='email' className='mt-2 fw-bold fs-6 required mb-1'>
                      Email
                    </label>
                    <Field
                      className='form-control mb-2'
                      name='email'
                      type='email'
                      placeholder='Enter email here'
                    />
                    <ErrorMessage name='email' component='div' className='error text-danger' />
                    <label htmlFor='role' className='mt-2 fw-bold fs-6 required mb-1'>
                      Select Role
                    </label>
                    <Field name='role'>
                      {({field, form}: any) => (
                        <Select
                          options={options}
                          name={field.name}
                          value={
                            options ? options.find((option) => option.value === field.value) : ''
                          }
                          onChange={(option: any) => form.setFieldValue(field.name, option.value)}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name='role'
                      component='div'
                      className='error text-danger mt-3 mb-2'
                    />
                    <label htmlFor='packageType' className='mt-2 fw-bold fs-6 required mb-1'>
                      Select Package
                    </label>
                    <Field name='packageType'>
                      {({field, form}: any) => (
                        <Select
                          options={packageOptions}
                          name={field.name}
                          value={
                            packageOptions
                              ? packageOptions.find((option) => option.value === field.value)
                              : ''
                          }
                          onChange={(option: any) => form.setFieldValue(field.name, option.value)}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name='packageType'
                      component='div'
                      className='error text-danger mt-3 mb-2'
                    />
                    <label htmlFor='loginExpiresOn' className='mt-2 fw-bold fs-6 required mb-1'>
                      Login Expiry date
                    </label>
                    <Field
                      name='loginExpiresOn'
                      type='date'
                      className='w-100 h40 rounded px-2'
                      style={{border: '1px solid lightgray'}}
                    />
                    <ErrorMessage
                      name='loginExpiresOn'
                      component='div'
                      className='error text-danger'
                    />
                  </div>
                  <div className='d-end mt-4'>
                    <button
                      type='submit'
                      className='btn btn-primary'
                      disabled={isSubmitting || isSubmitting}
                    >
                      {isSubmitting ? 'Sending...' : 'Submit'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      )}

      {showImportModal && (
        <Modal
          show={showImportModal}
          onHide={() => setShowImportModal(false)}
          centered
          backdrop='static'
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload the file to send bulk invites</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <label htmlFor='fileInput' className='mb-0 custom-file-upload'>
                  <input
                    type='file'
                    id='fileInput'
                    name='file'
                    onChange={handleFileChange}
                    accept='.csv'
                    className='cursor-pointer'
                  />
                </label>
              </div>
              <Button variant='primary' onClick={handleSendBulkInvites} disabled={isSubmitting}>
                {isSubmitting ? 'Sending...' : 'Submit'}
              </Button>
            </div>
            <div className='mt-4'>
              <div className='my-3'>
                <h4>File instructions</h4>
                <div>
                  <ol className='fw-bold'>
                    <li className='my-1'>
                      Email <span className='text-danger fw-normal'>(required)</span>
                    </li>
                    <li className='my-1'>
                      Role <span className='text-danger fw-normal'>(required)</span>
                    </li>
                    <li className='my-1'>
                      Login Expiry Date <span className='text-danger fw-normal'>(required)</span>
                    </li>
                    <li className='my-1'>
                      Package Type (basic, premium){' '}
                      <span className='text-danger fw-normal'>(required)</span>
                    </li>
                  </ol>
                </div>
              </div>
              <Button variant='secondary' className='mt-5' onClick={downloadTemplateFile}>
                Download Template File
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showDeleteModal && (
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete invite(s)?</p>
            <div className='d-flex justify-content-end'>
              <Button variant='secondary' className='m-1' onClick={() => setShowDeleteModal(false)}>
                Cancel
              </Button>
              <Button variant='danger' className='m-1' onClick={handleBulkDelete}>
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default InviteTableHeader

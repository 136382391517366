import {Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'
import {Badge, Button} from 'react-bootstrap'

import CourseSections from './components/CourseSections'
import ViewModule from './components/ViewModule'
import FetchingSpinner from '../../modules/common/FetchingSpinner'
import {useGetCourseById} from '../../queries/courses'
import GenericOffcanvas from '../../modules/common/Offcanvas'
import {KTIcon} from '../../../_metronic/helpers'

function CourseDetail() {
  const [showOffcanvas, setShowOffcanvas] = useState(false)
  const routeParams = useParams()
  const {courseId} = routeParams
  const courseResult = useGetCourseById(courseId || '')
  return (
    <>
      <div className='w-100 d-flex view-module-container'>
        {courseResult.isLoading && <FetchingSpinner />}
        {courseResult.data && (
          <Fragment>
            <div className='p-9 module-left-section lg-courses-sidebar' style={{width: '30%'}}>
              <CourseSections course={courseResult.data} />
            </div>
            <div className='d-none sm-courses-sidebar'>
              <Button variant='transparent' size='sm' onClick={() => setShowOffcanvas(true)}>
                <KTIcon iconName='entrance-left' className='fs-1' />
              </Button>
              <GenericOffcanvas
                showOffcanvas={showOffcanvas}
                onHideOffcanvas={() => setShowOffcanvas(false)}
                title={
                  <div className='d-flex align-items-center mb-5'>
                    <span className='fs-1 fw-bold mr10'>GOVCHAT</span>
                    <Badge bg='success'>
                      <span className='text-white'>BETA</span>
                    </Badge>
                  </div>
                }
              >
                <CourseSections course={courseResult.data} />
              </GenericOffcanvas>
            </div>
            <div className='p-9 module-right-section' style={{width: '70%'}}>
              <ViewModule course={courseResult.data} />
            </div>
          </Fragment>
        )}
      </div>
    </>
  )
}

export default CourseDetail

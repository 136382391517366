import {getSelectedCommunityId} from '../modules/common/utils'
import apiClient from './apiClient'

// Pipeline APIs
export const getAllPipelines = async () => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/pipelines`)
  return response.data?.data || []
}

export const getPipelineById = async (pipelineId: string) => {
  const response = await apiClient.get(`/c/${getSelectedCommunityId()}/pipelines/${pipelineId}`)
  return response.data?.data || {}
}

export const createPipeline = async (data: any) => {
  const response = await apiClient.post(`/c/${getSelectedCommunityId()}/pipelines`, data)
  return response.data?.data || {}
}

export const updatePipeline = async (pipelineId: string, data: any) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}`,
    data
  )
  return response.data?.data || {}
}

export const deletePipeline = async (pipelineId: string) => {
  const response = await apiClient.delete(`/c/${getSelectedCommunityId()}/pipelines/${pipelineId}`)
  return response.data?.data || {}
}

export const updatePipelineOrder = async (pipelineId: string, order: number) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/order/${order}`
  )
  return response.data?.data || {}
}

export const fetchPipelineShare = async (pipelineId: string) => {
  const response = await apiClient.get(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/share`
  )
  return response.data?.data || []
}

export const sharePipeline = async (pipelineId: string, recipientIds: string[]) => {
  const response = await apiClient.post(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/share`,
    {
      recipientIds,
    }
  )
  return response.data?.data || {}
}

export const revokePipeline = async (pipelineId: string, recipientIds: string[]) => {
  const response = await apiClient.post(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/revoke`,
    {
      recipientIds,
    }
  )
  return response.data?.data || {}
}

// Pipeline Stages APIs
export const createPipelineStage = async (pipelineId: string, data: any) => {
  const response = await apiClient.post(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/stages`,
    data
  )
  return response.data?.data || {}
}

export const updatePipelineStage = async (pipelineId: string, stageId: string, data: any) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/stages/${stageId}`,
    data
  )
  return response.data?.data || {}
}

export const getPipelineStagesByPipeline = async (pipelineId: string) => {
  const response = await apiClient.get(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/stages`
  )
  return response.data?.data || []
}

export const deletePipelineStage = async (pipelineId: string, stageId: string) => {
  const response = await apiClient.delete(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/stages/${stageId}`
  )
  return response.data?.data || {}
}

export const updatePipelineStageOrder = async (
  pipelineId: string,
  stageId: string,
  order: number
) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/stages/${stageId}/order/${order}`
  )
  return response.data?.data || {}
}

// Pipeline Stage Cards APIs
export const createPipelineStageCard = async (pipelineId: string, stageId: string, data: any) => {
  const response = await apiClient.post(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/stages/${stageId}/cards`,
    data
  )
  return response.data?.data || {}
}

export const updatePipelineStageCard = async (
  pipelineId: string,
  stageId: string,
  cardId: string,
  data: any
) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/stages/${stageId}/cards/${cardId}`,
    data
  )
  return response.data?.data || {}
}

export const updatePipelineStageCardStatus = async (
  pipelineId: string,
  stageId: string,
  cardId: string,
  status: any
) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/stages/${stageId}/cards/${cardId}/status`,
    status
  )
  return response.data?.data || {}
}

export const getPipelineStageCards = async (pipelineId: string, stageId: string) => {
  const response = await apiClient.get(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/stages/${stageId}/cards`
  )
  return response.data?.data || []
}

export const deletePipelineStageCard = async (
  pipelineId: string,
  stageId: string,
  cardId: string
) => {
  const response = await apiClient.delete(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/stages/${stageId}/cards/${cardId}`
  )
  return response.data?.data || {}
}

export const updatePipelineStageCardOrder = async (
  pipelineId: string,
  stageId: string,
  cardId: string,
  order: number,
  {newStageId}: {newStageId?: string}
) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/stages/${stageId}/cards/${cardId}/order/${order}`,
    {newStageId}
  )
  return response.data?.data || {}
}

export const createPipelineStageCardComment = async (
  pipelineId: string,
  stageId: string,
  cardId: string,
  data: any
) => {
  const response = await apiClient.post(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/stages/${stageId}/cards/${cardId}/comments`,
    data
  )
  return response.data?.data || {}
}

export const updatePipelineStageCardComment = async (
  pipelineId: string,
  stageId: string,
  cardId: string,
  commentId: string,
  data: any
) => {
  const response = await apiClient.put(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/stages/${stageId}/cards/${cardId}/comments/${commentId}`,
    data
  )
  return response.data?.data || {}
}

export const getPipelineStageCardComments = async (
  pipelineId: string,
  stageId: string,
  cardId: string,
  page: number = 1,
  limit: number = 1000
) => {
  const response = await apiClient.get(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/stages/${stageId}/cards/${cardId}/comments`,
    {
      params: {
        page,
        limit,
      },
    }
  )
  return response.data?.data || []
}

export const deletePipelineStageCardComment = async (
  pipelineId: string,
  stageId: string,
  cardId: string,
  commentId: string
) => {
  const response = await apiClient.delete(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/stages/${stageId}/cards/${cardId}/comments/${commentId}`
  )
  return response.data?.data || {}
}

export const postPipelineStageCardCommentLike = async (
  pipelineId: string,
  stageId: string,
  cardId: string,
  commentId: number
) => {
  const response = await apiClient.post(
    `/c/${getSelectedCommunityId()}/pipelines/${pipelineId}/stages/${stageId}/cards/${cardId}/comments/${commentId}/likes`
  )
  return response.data?.data || {}
}

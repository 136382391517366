import {useAuth} from '../../../modules/auth'
import {AsideMenuItem} from './AsideMenuItem'

import './aside.css'

export function AsideMenuMain() {
  const {selectedCommunity, unreadPipelineNotificationsCount} = useAuth()
  const isPremiumUser = selectedCommunity?.isPremiumUser

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content'>
          <span className='menu-section text-muted text-uppercase fw-bolder fs-8 ls-1'>Pages</span>
        </div>
      </div>
      <AsideMenuItem to='/feeds/public' title='Community' svgIcon='/media/svg/general/users.svg' />
      {isPremiumUser && (
        <>
          <AsideMenuItem
            to='/pipeline'
            title='Pipelines'
            titleChildren={
              unreadPipelineNotificationsCount > 0 && (
                <span className='notification-dot bg-danger' style={{marginTop: '-15px'}}></span>
              )
            }
            className='position-relative'
            svgIcon='/media/svg/general/chart.svg'
          />
          <AsideMenuItem to='/courses' title='Courses' svgIcon='/media/svg/general/course.svg' />
          <AsideMenuItem to='/govchat' title='Govchat' svgIcon='/media/svg/general/chat.svg' />
          {!selectedCommunity?.isTeamMember && (
            <AsideMenuItem
              to='/support'
              title='Support'
              svgIcon='/media/svg/general/information.svg'
            />
          )}
        </>
      )}
      <AsideMenuItem
        to='/resources'
        title='Resources'
        svgIcon='/media/svg/general/scan-barcode.svg'
      />
      <AsideMenuItem to='/notes' title='Notes' svgIcon='/media/svg/general/medal-star.svg' />
      <AsideMenuItem
        to='/community'
        title='Member Directory'
        svgIcon='/media/svg/general/address-book.svg'
      />
      <AsideMenuItem to='/calendar' title='Calendar' svgIcon='/media/svg/general/calendar.svg' />
      <AsideMenuItem
        to='/leaderboard'
        title='Leaderboard'
        svgIcon='/media/svg/general/barcode.svg'
      />
    </>
  )
}

import React, {useEffect, useState} from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Accordion from 'react-bootstrap/Accordion'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {Button} from 'react-bootstrap'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'

import CustomDropDown from '../../../modules/common/CustomDropDown'
import CoursesForm from './CoursesForm'
import CourseModuleForm from './CourseModuleForm'
import {CourseSection} from './CourseSection'
import {CourseSectionForm} from './CourseSectionForm'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import {
  useDeleteCourse,
  useUpdateCourseModuleOrder,
  useUpdateCourseSectionOrder,
} from '../../../queries/courses'
import {useAuth} from '../../../modules/auth'
import CourseModule from './CourseModule'

interface CourseSectionsProps {
  course?: any
}

const CourseSections: React.FC<CourseSectionsProps> = ({course}) => {
  const navigate = useNavigate()
  const {selectedCommunity} = useAuth()
  const [searchParams] = useSearchParams()
  const {mutateAsync: deleteCourse, isPending: isDeletingCourse} = useDeleteCourse()
  const courseSectionOrderMutation = useUpdateCourseSectionOrder()
  const courseModuleOrderMutation = useUpdateCourseModuleOrder()
  const [courseItems, setCourseItems] = useState(course.items)
  const [showSectionFormModal, setShowSectionFormModal] = useState(false)
  const [showDeleteCourseModal, setShowDeleteCourseModal] = useState(false)
  const [showEditCourseModal, setShowEditCourseModal] = useState(false)
  const [showModuleFormModal, setShowModuleFormModal] = useState(false)
  const [selectedSectionId, setSelectedSectionId] = useState<any>(null)
  const [activeSectionKey, setActiveSectionKey] = useState(searchParams.get('sectionId'))

  useEffect(() => {
    setActiveSectionKey(searchParams.get('sectionId'))
  }, [searchParams.get('sectionId')])

  useEffect(() => {
    setCourseItems(course.items)
  }, [course.items])

  const handleAddModule = (sectionId: number) => {
    setSelectedSectionId(sectionId)
    setShowModuleFormModal(true)
  }

  const handleDeleteCourse = async () => {
    try {
      await deleteCourse(course.uuid)
      navigate('/courses')
      setShowDeleteCourseModal(false)
    } catch {}
  }

  const courseOptions = [
    {label: 'Edit Course', handler: () => setShowEditCourseModal(true)},
    {label: 'Add Section', handler: () => setShowSectionFormModal(true)},
    {label: 'Add Module', handler: () => setShowModuleFormModal(true)},
  ]
  if (selectedCommunity?.permissions.canDeleteCourse) {
    courseOptions.push({
      label: 'Delete Course',
      handler: () => setShowDeleteCourseModal(true),
    })
  }

  const handleCourseItemDragEnd = async (result: any) => {
    if (!result.destination) {
      return
    }
    const [oldItems, reorderedItems] = [Array.from(courseItems), Array.from(courseItems)]
    const [removed] = reorderedItems.splice(result.source.index, 1)
    reorderedItems.splice(result.destination.index, 0, removed)

    setCourseItems(reorderedItems)

    const [id, type] = result.draggableId.split('_')
    try {
      if (type === 'section') {
        await courseSectionOrderMutation.mutateAsync({
          courseId: course.uuid,
          sectionId: id,
          order: result.destination.index + 1,
        })
      } else if (type === 'module') {
        await courseModuleOrderMutation.mutateAsync({
          courseId: course.uuid,
          moduleId: id,
          order: result.destination.index + 1,
        })
      }
    } catch {
      setCourseItems(oldItems)
    }
  }

  const handleAccordionChange = (newActiveKey: any) => {
    setActiveSectionKey(newActiveKey)
  }

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <div>
          <h2>{course.title?.length > 24 ? course.title?.slice(0, 24) + '...' : course.title}</h2>
        </div>
        {selectedCommunity?.permissions.canCreateEditCourse && (
          <div>
            <CustomDropDown elementId='set-dropdown' options={courseOptions} />
          </div>
        )}
      </div>

      {selectedCommunity?.permissions.canCreateEditCourse && (
        <Button
          variant='primary'
          className='w-100 mt-3'
          onClick={() => setShowSectionFormModal(true)}
        >
          Add Section
        </Button>
      )}
      {selectedCommunity?.isMember && (
        <div className='mt-4'>
          <ProgressBar
            now={course.courseProgress}
            label={`${course.courseProgress}%`}
            variant={course.courseProgress < 100 ? 'primary' : 'success'}
            className='rounded-4'
            style={{height: '23px'}}
          />
        </div>
      )}

      <div className='mt-9'>
        <DragDropContext onDragEnd={handleCourseItemDragEnd}>
          <Accordion activeKey={activeSectionKey} onSelect={handleAccordionChange}>
            <Droppable droppableId='courseItems'>
              {(provided: any) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {courseItems.map((item: any, index: number) => (
                    <Draggable
                      key={item.id}
                      draggableId={`${item.id}_${item.type}`}
                      index={index}
                      isDragDisabled={!selectedCommunity?.permissions.canCreateEditCourse}
                    >
                      {(provided: any) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {item.type === 'section' && (
                            <CourseSection
                              course={course}
                              section={item}
                              handleAddModule={handleAddModule}
                            />
                          )}

                          {item.type === 'module' && (
                            <CourseModule
                              course={course}
                              sectionModule={item}
                              sectionStatus={item.status}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Accordion>
        </DragDropContext>
      </div>

      {showSectionFormModal && (
        <CourseSectionForm
          showModal={showSectionFormModal}
          onHideModal={() => setShowSectionFormModal(false)}
          course={course}
        />
      )}

      {showDeleteCourseModal && (
        <ConfirmationModal
          showModal={showDeleteCourseModal}
          subtitle='Are you sure you want to delete this course?'
          onHideModal={() => setShowDeleteCourseModal(false)}
          disableBtns={isDeletingCourse}
          onConfirm={handleDeleteCourse}
          onCancel={() => setShowDeleteCourseModal(false)}
        />
      )}
      {showEditCourseModal && (
        <CoursesForm
          course={course}
          show={showEditCourseModal}
          handleClose={() => setShowEditCourseModal(false)}
        />
      )}
      {showModuleFormModal && (
        <CourseModuleForm
          show={showModuleFormModal}
          handleClose={() => {
            setSelectedSectionId(null)
            setShowModuleFormModal(false)
          }}
          course={course}
          sectionId={selectedSectionId}
        />
      )}
    </div>
  )
}

export default CourseSections

import {useState} from 'react'
import {Button, Modal} from 'react-bootstrap'

import FolderForm from './FolderForm'
import CustomDropdown from '../../../modules/common/CustomDropDown'
import UploadResource from './UploadResource'

import './UploadResource.css'
import {useNavigate, useSearchParams} from 'react-router-dom'

interface IResourceHeader {
  changeDisplay: any
  showBlock: boolean
  canManageResources: boolean
}

const ResourcesHeader = ({changeDisplay, showBlock, canManageResources}: IResourceHeader) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [search, setSearch] = useState('')
  const [showFolderModal, setShowFolderModal] = useState(false)
  const [selectedResource, setSelectedResource] = useState<any>(null)
  const [showUploadResourcesModal, setShowUploadResourcesModal] = useState(false)
  const [isUploading, setIsUploading] = useState(false)

  const handleClose = () => setShowFolderModal(false)

  return (
    <div>
      <div className='community-container flex-wrap justify-content-between p-3 align-items-center d-flex'>
        <div className='mx-3 d-flex align-items-center'>
          <h1 className='mx-2'>Resources</h1>
        </div>
        <div className='d-flex flex-wrap align-items-center mb-5'>
          <div className='d-flex'>
            <span
              style={{
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
              }}
              className='align-items-center d-flex px-3 h50 bg-light-secondary'
            >
              <i className='fa fa-magnifying-glass'></i>
            </span>
            <input
              style={{
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
              }}
              onChange={(event) => setSearch(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  let params = `?search=${search}`
                  if (searchParams.get('folderId')) {
                    params += `&folderId=${searchParams.get('folderId')}`
                  }
                  navigate(params)
                }
              }}
              value={search}
              className='border-0 input-field bg-light-secondary w250 outline-none h50'
              type='text'
              name='resourcesSearch'
              placeholder='Search'
            />
          </div>
          <div>
            {canManageResources && (
              <>
                <CustomDropdown
                  elementId={'resources'}
                  dropdownTitle={
                    <Button className='header-button h50 ml15' variant='primary'>
                      <i className='fa-solid fa-plus'></i> Add new
                    </Button>
                  }
                  options={[
                    {
                      label: 'Folder Upload',
                      handler: () => {
                        setSelectedResource(null)
                        setShowFolderModal(true)
                      },
                    },
                    {
                      label: 'Files Upload',
                      handler: () => setShowUploadResourcesModal(true),
                    },
                  ]}
                />
              </>
            )}
          </div>
          <div className='d-flex align-items-center mx-3' onClick={changeDisplay}>
            <div className='p-2 cursor-pointer rounded mx-2 bg-primary'>
              {showBlock ? (
                <i className='text-light bi bi-list-ul fs-2x'></i>
              ) : (
                <i className='text-light bi bi-grid-fill fs-2x'></i>
              )}
            </div>
          </div>
        </div>
      </div>
      {showFolderModal && (
        <FolderForm
          showFolderModal={showFolderModal}
          handleClose={handleClose}
          selectedResource={selectedResource}
        />
      )}
      {showUploadResourcesModal && (
        <Modal
          show={showUploadResourcesModal}
          onHide={() => setShowUploadResourcesModal(false)}
          size='lg'
          centered
          backdrop='static'
        >
          <Modal.Body>
            <UploadResource isUploading={isUploading} setIsUploading={setIsUploading} />
            <div className='d-end'>
              <Button
                variant='secondary'
                disabled={isUploading}
                onClick={() => setShowUploadResourcesModal(false)}
              >
                Discard
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default ResourcesHeader

import {useCallback} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

type QueryParamHandler = {
  addQueryParam: (key: string, value: string) => void
  removeQueryParam: (key: string) => void
}

const useQueryParam = (): QueryParamHandler => {
  const navigate = useNavigate()
  const location = useLocation()

  const addQueryParam = useCallback(
    (key: string, value: string) => {
      const params = new URLSearchParams(location.search)
      params.set(key, value)

      navigate(
        {
          pathname: location.pathname,
          search: params.toString(),
        },
        {replace: true}
      )
    },
    [location, navigate]
  )

  const removeQueryParam = useCallback(
    (key: string) => {
      const params = new URLSearchParams(location.search)
      params.delete(key)

      navigate(
        {
          pathname: location.pathname,
          search: params.toString(),
        },
        {replace: true}
      )
    },
    [location, navigate]
  )

  return {addQueryParam, removeQueryParam}
}

export default useQueryParam

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import Select from 'react-select'
import moment from 'moment'
import {useQuery} from '@tanstack/react-query'
import {Button} from 'react-bootstrap'

import {useAuth} from '../../../../auth'
import {IProfileDetails, profileDetailsInitValues as initialValues} from '../SettingsModel'
import CloseSvg from './CloseSvg'
import {updateProfile} from '../../../../auth/core/_requests'
import EditSvg from './EditSvg'
import {states, cities, isProfileIncomplete, getTimezoneOptions} from '../../../../common/utils'
import apiClient from '../../../../../apis/apiClient'
import {useGetStripePortal} from '../../../../../queries/users'

import './ProfileDetails.css'

const getValidationSchema = () => {
  let schema = {
    firstName: Yup.string().required('First name is required').max(50),
    lastName: Yup.string().required('Last name is required').max(50),
    bio: Yup.string().required('Bio is required').max(150),
    hasCertificate: Yup.string().required('Selecting the option is required'),
    hasWebsite: Yup.string().required('Selecting the option is required'),
    companyName: Yup.string().required('Company name is required').max(60),
    city: Yup.string().required('City is required').max(20),
    state: Yup.array().min(1, 'Location is required'),
    timezone: Yup.string().required('Timezone is required'),
  }
  return Yup.object().shape(schema)
}

const isValidUrl = (url: string) => {
  const urlPattern =
    /^((http|https):\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i
  return urlPattern.test(url)
}

const ProfileDetails: React.FC = () => {
  const {currentUser, setCurrentUser, selectedCommunity} = useAuth()
  const navigate = useNavigate()
  const stripePortalResult = useGetStripePortal(currentUser?.id)

  initialValues.firstName = currentUser?.firstName || ''
  initialValues.lastName = currentUser?.lastName || ''
  initialValues.companyName = currentUser?.companyName || ''
  initialValues.city = currentUser?.city || ''
  initialValues.profilePic = (currentUser?.profilePic && currentUser.absoluteProfilePath) || ''
  initialValues.hasWebsite = currentUser?.userWebsites.length ? 'yes' : 'no'
  initialValues.hasCertificate = currentUser?.userCertifications.length ? 'yes' : 'no'
  initialValues.bio = currentUser?.bio || ''
  initialValues.timezone = currentUser?.timezone || ''

  if (currentUser && currentUser.state) {
    const existingState = states.find((item: any) => item.value === currentUser.state)
    initialValues.state = [{value: existingState?.value, label: existingState?.name}]
  }

  const [location, setLocation] = useState<any>(initialValues.state ? initialValues.state : [])

  const [data, setData] = useState<IProfileDetails>(initialValues)
  const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [userCertificates, setUserCertificates] = useState(
    currentUser?.userCertifications?.map((certification: any) => certification?.certificate) || []
  )
  const [userWebsites, setUserWebsites] = useState(
    currentUser?.userWebsites?.map((website: {websiteUrl: string}) => website.websiteUrl) || ['']
  )
  const [base64File, setBase64File] = useState<any>(data?.profilePic || null)
  const [customError, setCustomError] = useState<any>({})

  const textInput = useRef<any>(null)
  const isProfileIncompleted = isProfileIncomplete(currentUser)

  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: getValidationSchema(),
    onSubmit: async (values) => {
      let error: any = {}
      if (
        formik.values.hasWebsite === 'yes' &&
        userWebsites.filter((website: string) => isValidUrl(website)).length === 0
      ) {
        error.website = 'Please enter at least one valid url of website'
      }
      if (formik.values.hasCertificate === 'yes' && userCertificates.length === 0) {
        error.certificate = 'Please select at least one certificate'
      }

      if (!base64File) {
        error.profilePic = 'Please select a profile picture'
      }

      if (error.website || error.certificate || error.profilePic) {
        setCustomError(error)
        return
      }

      setLoading(true)
      setTimeout(async () => {
        const updatedData = Object.assign(data, values)
        updatedData.certificatesIds = userCertificates.map((certificate: any) => certificate?.id)
        updatedData.userWebsites = userWebsites.filter((website: string) => isValidUrl(website))
        updatedData.removeProfileImage = !base64File
        try {
          await updateProfile(currentUser, updatedData, selectedFile, values.state[0].value).then(
            (res: any) => {
              const {data} = res
              setCurrentUser(data.data.user)
              toast.success('Profile updated.', {
                position: toast.POSITION.BOTTOM_RIGHT,
              })
              navigate(`/view-profile/${currentUser?.id}`)
            }
          )
          setData(updatedData)
          setLoading(false)
        } catch (err: any) {
          setLoading(false)
          toast.error(err?.response?.data?.message || 'There is some error. Please try later.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          })
        }
      }, 1000)
    },
  })

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      setBase64File(null)
      return
    }

    const selectedFile = e.target.files[0]

    // Add a null check before accessing the size property
    if (selectedFile && selectedFile.size) {
      // Check if the file size exceeds 512 KB
      if (selectedFile.size > 512 * 1024) {
        toast.error('Image size should be less than 512 KB', {
          position: toast.POSITION.BOTTOM_RIGHT,
        })
        return
      }
    }

    setSelectedFile(selectedFile)
    setBase64File(null)

    getBase64(selectedFile, (result: any) => {
      setBase64File(result)
      setCustomError({...customError, profilePic: ''})
    })
  }

  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const subscriptionDate = () => {
    return moment(new Date(1693222625 * 1000))
      .format('DD/MM/YYYY')
      .toString()
  }

  const handleManageSubscription = () => {
    window.open(stripePortalResult.data.redirectUrl, '_self')
  }

  const onChangeWebsiteLink = (value: string, index: number) => {
    const newWebsites = [...userWebsites]
    newWebsites[index] = value
    setUserWebsites(newWebsites)

    if (isValidUrl(value)) {
      setCustomError({...customError, website: ''})
    }
  }

  // fetch certificates
  const {data: certificates} = useQuery({
    queryKey: ['certificates'],
    queryFn: () =>
      apiClient
        .get('/users/all-certificates')
        .then((response) => response?.data?.data?.certificates),
  })

  const timezoneOptions = getTimezoneOptions()
  return (
    <div className='card mb-5 mb-xl-10 w-100'>
      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {isProfileIncompleted && (
              <p style={{color: 'red'}}>Please complete your profile to use the system</p>
            )}
            <div className='row mb-6'>
              <div className='position-relative'>
                <input
                  id='file-input'
                  type='file'
                  accept='image/*'
                  hidden={true}
                  onChange={onSelectFile}
                  ref={textInput}
                />
                <div className='image-input card image-input-outline' data-kt-image-input='true'>
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{
                      backgroundImage: `url(${base64File})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                  ></div>
                </div>
                <div style={{position: 'absolute', left: 70, top: 110}}>
                  {base64File && (
                    <a
                      className='cursor-pointer'
                      onClick={() => {
                        textInput.current.value = ''
                        setSelectedFile(null)
                        setBase64File(null)
                      }}
                    >
                      <CloseSvg />
                    </a>
                  )}
                  <a
                    className='cursor-pointer'
                    onClick={() => textInput && textInput.current && textInput.current.click()}
                  >
                    <EditSvg />
                  </a>
                </div>
                {customError?.profilePic && (
                  <div className='fv-plugins-message-container mt-3' style={{color: 'red'}}>
                    <div className='fv-help-block mt-2'>{customError?.profilePic}</div>
                  </div>
                )}
                <div style={{fontSize: '12px'}} className='mt-5 fw-bold required'>
                  Time to express, make your profile pic the best!
                </div>
                <div style={{fontSize: '10px'}}>
                  Allowed file types: png, jpg, jpeg. Recommended size is 512x512.
                </div>
                {stripePortalResult.data?.redirectUrl && (
                  <Button
                    variant='outline-primary'
                    className='mt-5 btn btn-outline'
                    onClick={handleManageSubscription}
                  >
                    Manage My Subscriptions
                  </Button>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <div className='col-lg-6 fv-row'>
                <label htmlFor='firstName' className='required col-form-label fw-bold fs-6'>
                  First Name
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='First name'
                  {...formik.getFieldProps('firstName')}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.firstName}</div>
                  </div>
                )}
              </div>
              <div className='col-lg-6 fv-row'>
                <label htmlFor='lastName' className='required col-form-label fw-bold fs-6'>
                  Last Name
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Last name'
                  {...formik.getFieldProps('lastName')}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.lastName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 required col-form-label fw-bold fs-6'>Company Name</label>

              <div className=' fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Company name'
                  {...formik.getFieldProps('companyName')}
                />
                {formik.touched.companyName && formik.errors.companyName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.companyName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <div className='col-lg-4 fv-row'>
                <label className='col-lg-4 required col-form-label fw-bold fs-6'>City</label>
                <Select
                  options={cities.map((item: any) => ({value: item.value, label: item.name}))}
                  closeMenuOnSelect={true}
                  className='select-options'
                  defaultValue={
                    initialValues.city
                      ? [{label: initialValues.city, value: initialValues.city}]
                      : []
                  }
                  onChange={(selectedOption) => {
                    formik.setFieldValue('city', selectedOption ? selectedOption.value : '')
                  }}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.city}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 fv-row'>
                <label className='col-lg-4 required col-form-label fw-bold fs-6'>State</label>
                <Select
                  options={states.map((item: any) => {
                    return {value: item.value, label: item.name}
                  })}
                  closeMenuOnSelect={true}
                  className='select-options'
                  value={location}
                  defaultValue={location}
                  onChange={(e) => {
                    formik.setFieldValue('state', [e])
                    setLocation([e])
                  }}
                />
                {formik.touched.state && formik.errors.state && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>Location is required</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 fv-row'>
                <label
                  className='col-lg-4 required col-form-label fw-bold fs-6 w-auto'
                  htmlFor='timezone'
                >
                  Timezone
                </label>
                <Select
                  id='timezone'
                  name='timezone'
                  className='form-control p-0'
                  value={timezoneOptions.find((option) => option.value === formik.values.timezone)}
                  onChange={(selectedOption: any) => {
                    formik.setFieldValue('timezone', selectedOption.value)
                  }}
                  options={timezoneOptions}
                />
                {formik.touched.timezone && formik.errors.timezone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.timezone}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='fw-bold fs-6 required'>
                Bio (Tell us about your business, products and the services you provide)
              </label>

              <div className=' fv-row'>
                <textarea
                  rows={3}
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Add your bio'
                  {...formik.getFieldProps('bio')}
                ></textarea>
                {formik.touched.bio && formik.errors.bio && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.bio}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <h1 className='fw-bold mt-5 fs-5'>Government Certifications</h1>
              <div className='d-flex flex-row'>
                <div className='align-items-center mt-5'>
                  <input
                    className='form-check-input cursor-pointer rounded-circle mt-1 small-radio'
                    type='radio'
                    id='hasCertificate'
                    value='no'
                    checked={formik.values.hasCertificate === 'no'}
                    onChange={(e) => {
                      setUserCertificates([])
                      formik.setFieldValue('hasCertificate', e.target.value)
                    }}
                  />
                  <label className='form-check-label text-dark fs-6 ms-3'>
                    I don’t qualify for a certification
                  </label>
                </div>
                <div className='align-items-center mt-5 mx-19'>
                  <input
                    className='form-check-input cursor-pointer rounded-circle mt-1 small-radio'
                    type='radio'
                    id='hasCertificate'
                    value='yes'
                    checked={formik.values.hasCertificate === 'yes'}
                    onChange={(e) => {
                      formik.setFieldValue('hasCertificate', e.target.value)
                    }}
                  />
                  <label className='form-check-label text-dark fs-6 ms-3' htmlFor='qualifyRadio'>
                    I qualify
                  </label>
                </div>
              </div>
              {formik.values.hasCertificate === 'yes' && (
                <>
                  {customError?.certificate && (
                    <div className='fv-plugins-message-container mt-3' style={{color: 'red'}}>
                      <div className='fv-help-block mt-2'>{customError?.certificate}</div>
                    </div>
                  )}
                  <Select
                    options={certificates}
                    getOptionValue={(certificate: any) => certificate?.id}
                    getOptionLabel={(certificate: any) => certificate?.name}
                    isMulti
                    defaultValue={userCertificates}
                    className='basic-multi-select mt-5 w-100 cursor-pointer'
                    classNamePrefix='Select Certificates'
                    onChange={(values: any) => {
                      if (values.length > 0) {
                        setCustomError({...customError, certificate: ''})
                      }
                      setUserCertificates(values)
                    }}
                  />
                </>
              )}
            </div>

            <div className='row mb-6'>
              <h1 className='fw-bold mt-5 fs-5'>Websites</h1>
              <div className='d-flex flex-row'>
                <div className='align-items-center mt-5'>
                  <input
                    className='form-check-input cursor-pointer rounded-circle mt-1 small-radio'
                    type='radio'
                    id='hasWebsite'
                    name='hasWebsite'
                    value='no'
                    checked={formik.values.hasWebsite === 'no'}
                    onChange={(e) => {
                      setUserWebsites([])
                      formik.setFieldValue('hasWebsite', e.target.value)
                    }}
                  />
                  <label className='form-check-label text-dark fs-6 ms-3'>
                    I Don't Have a Website
                  </label>
                </div>
                <div className='align-items-center mt-5 mx-19'>
                  <input
                    className='form-check-input cursor-pointer rounded-circle mt-1 small-radio'
                    type='radio'
                    id='hasWebsite'
                    name='hasWebsite'
                    value='yes'
                    checked={formik.values.hasWebsite === 'yes'}
                    onChange={(e) => {
                      setUserWebsites([''])
                      formik.setFieldValue('hasWebsite', e.target.value)
                    }}
                  />
                  <label
                    className='form-check-label text-dark fs-6 ms-3'
                    htmlFor='websiteQualifyRadio'
                  >
                    I Have a Website
                  </label>
                </div>
              </div>
              {formik.values.hasWebsite === 'yes' && (
                <div>
                  {customError?.website && (
                    <div
                      className='fv-plugins-message-container '
                      style={{color: 'red', marginTop: '15px'}}
                    >
                      <div className='fv-help-block'>{customError?.website}</div>
                    </div>
                  )}
                  {userWebsites?.map((website: string, index: number) => (
                    <div key={index}>
                      <input
                        type='text'
                        className='form-control mt-5 w-100'
                        placeholder='Paste your website link here...'
                        value={website}
                        onChange={(e) => onChangeWebsiteLink(e.target.value, index)}
                      />
                      {website.length === 0 || isValidUrl(website) ? null : (
                        <span className='text-danger'>Please enter a valid URL</span>
                      )}
                      {userWebsites?.length === index + 1 && userWebsites?.length < 3 && (
                        <Button
                          className='btn btn-primary mt-3'
                          size='sm'
                          style={{padding: '8px'}}
                          onClick={() => setUserWebsites([...userWebsites, ''])}
                        >
                          <i className='fa-solid fs-3 p-0 fa-plus'></i>
                        </Button>
                      )}
                      {index > 0 && (
                        <Button
                          size='sm'
                          className='ml-5 btn btn-danger mt-3 mx-3'
                          style={{padding: '8px'}}
                          onClick={() =>
                            setUserWebsites(userWebsites.filter((_: any, i: number) => i !== index))
                          }
                        >
                          <i className='fa-solid p-0 fs-3 fa-minus'></i>
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {!selectedCommunity?.isCommunityAdmin && !selectedCommunity?.isTeamMember && (
              <>
                <div className='row mb-6'>
                  <label className='col-lg-4  col-form-label fw-bold fs-6'>
                    Subscription Status
                  </label>

                  <div className=' fv-row'>Active</div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4  col-form-label fw-bold fs-6'>Subscription End</label>

                  <div className=' fv-row'>{subscriptionDate()}</div>
                </div>
              </>
            )}
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            {!isProfileIncompleted && (
              <a
                className='btn btn-light mx-3'
                onClick={() => navigate(`/view-profile/${currentUser?.id}`)}
              >
                Discard
              </a>
            )}

            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileDetails}

import React from 'react'
import {ComingSoon} from '../../modules/common/ComingSoon'

function CrmIndex() {
  return (
    <div>
      <ComingSoon />
    </div>
  )
}

export default CrmIndex

import {useEffect, useState} from 'react'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {Button, Dropdown} from 'react-bootstrap'
import Select from 'react-select'

import PipeLineStage from './PipeLineStage'
import {
  useGetPipelineById,
  useUpdatePipelineStageOrder,
  useUpdatePipelineStageCardOrder,
  useUpdatePipelineStageCardStatus,
} from '../../../queries/pipelines'
import PipeLineStageForm from './PipeLineStageForm'
import FetchingSpinner from '../../../modules/common/FetchingSpinner'
import PipelineStatusCard from './PipelineStatusCard'
import PipeLineCardForm from './PipeLineCardForm'

function PipeLineStages({pipelineNotificationsResult}: {pipelineNotificationsResult: any}) {
  const [searchParams] = useSearchParams()
  const pipelineId: any = searchParams.get('pipelineId')
  const [currentPipelineId, setCurrentPipelineId] = useState<any>(pipelineId)
  const [showAddStageModal, setShowAddStageModal] = useState(false)
  const [showContractModal, setShowContractModal] = useState(false)
  const [isDraggingCard, setIsDraggingCard] = useState(false)
  const [pipelineStages, setPipelineStages] = useState<any>([])
  const [filterOption, setFilterOption] = useState<any>('')
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const navigate = useNavigate()
  const {data: singlePipelineData, isLoading} = useGetPipelineById(currentPipelineId)
  const pipelineStageOrderMutation = useUpdatePipelineStageOrder()
  const stageCardOrderMutation = useUpdatePipelineStageCardOrder()
  const updateCardStatus = useUpdatePipelineStageCardStatus()

  useEffect(() => {
    setCurrentPipelineId(pipelineId)
  }, [pipelineId])

  useEffect(() => {
    setPipelineStages(singlePipelineData?.pipeline?.pipelineStages || [])
  }, [singlePipelineData])

  const handleStageCardDragEnd = async (result: any) => {
    const {source, destination, draggableId} = result

    // Handle card status update
    if (['submitted', 'won', 'lost', 'inProgress'].includes(destination.droppableId)) {
      try {
        await updateCardStatus.mutateAsync({
          pipelineId,
          cardId: draggableId,
          stageId: source.droppableId,
          status: {status: destination.droppableId},
        })
        return
      } catch (error) {
        console.error('Error updating pipeline status:', error)
        return
      }
    }

    // Find the source and destination stages
    const sourceStageIndex = pipelineStages.findIndex(
      (stage: any) => stage.uuid === source.droppableId
    )
    const destinationStageIndex = pipelineStages.findIndex(
      (stage: any) => stage.uuid === destination.droppableId
    )

    // If source and destination are the same stage
    if (sourceStageIndex === destinationStageIndex) {
      const stageCards = Array.from(pipelineStages[sourceStageIndex].pipelineStageCards)

      // Remove card from the source index
      const [removedCard] = stageCards.splice(source.index, 1)

      // Add the card to the new position in the same stage
      stageCards.splice(destination.index, 0, removedCard)

      // Update the state with the new card order for this stage
      const updatedPipelineStages = [...pipelineStages]
      updatedPipelineStages[sourceStageIndex].pipelineStageCards = stageCards

      setPipelineStages(updatedPipelineStages)

      try {
        await stageCardOrderMutation.mutateAsync({
          pipelineId,
          stageId: source.droppableId,
          cardId: draggableId,
          order: destination.index + 1,
        })
      } catch {
        setPipelineStages(pipelineStages)
      }
    } else {
      // Cross-stage drag-and-drop handling
      const sourceStageCards = Array.from(pipelineStages[sourceStageIndex].pipelineStageCards)
      const destinationStageCards = Array.from(
        pipelineStages[destinationStageIndex].pipelineStageCards
      )

      // Remove card from the source stage
      const [removedCard] = sourceStageCards.splice(source.index, 1)

      // Add card to the destination stage
      destinationStageCards.splice(destination.index, 0, removedCard)

      // Update both source and destination stages' cards
      const updatedPipelineStages = [...pipelineStages]
      updatedPipelineStages[sourceStageIndex].pipelineStageCards = sourceStageCards
      updatedPipelineStages[destinationStageIndex].pipelineStageCards = destinationStageCards

      setPipelineStages(updatedPipelineStages)

      try {
        await stageCardOrderMutation.mutateAsync({
          pipelineId,
          stageId: source.droppableId, // Original stage
          cardId: draggableId,
          order: destination.index + 1,
          newStageId: destination.droppableId, // New stage if cross-stage drag
        })
      } catch {
        setPipelineStages(pipelineStages)
      }
    }
  }

  const handleStageDragEnd = async (result: any) => {
    const [oldStages, reorderedStages] = [Array.from(pipelineStages), Array.from(pipelineStages)]
    const [removed] = reorderedStages.splice(result.source.index, 1)
    reorderedStages.splice(result.destination.index, 0, removed)

    setPipelineStages(reorderedStages)
    try {
      await pipelineStageOrderMutation.mutateAsync({
        pipelineId,
        stageId: result.draggableId,
        order: result.destination.index + 1,
      })
    } catch {
      setPipelineStages(oldStages)
    }
  }

  const handleStageORCardDragEnd = async (result: any) => {
    const {source, destination} = result
    setIsDraggingCard(false)
    // If there's no destination, exit early
    if (!destination) {
    }

    // If the card is dropped in the same place, exit early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return
    }

    if (result.type === 'card') {
      return handleStageCardDragEnd(result)
    }

    return handleStageDragEnd(result)
  }

  if (isLoading) {
    return <FetchingSpinner />
  }

  const filterOptions = [
    {label: 'Submitted', value: 'submitted'},
    {label: 'Won', value: 'won'},
    {label: 'Lost', value: 'lost'},
    {label: 'In Progress', value: 'inProgress'},
    {label: 'No Bid', value: 'noBid'},
  ]

  const handleResetFilter = () => {
    setFilterOption('')
    setIsFilterOpen(false)
    navigate(`?pipelineId=${pipelineId}`)
  }

  const handleApplyFilter = () => {
    setIsFilterOpen(false)
    let params = `?pipelineId=${pipelineId}`
    if (filterOption) {
      params += `&status=${filterOption}`
    }
    navigate(params)
  }

  return (
    <div className='pipeline-view-container overflow-x-scroll w-100'>
      <div className='d-end lg-sidebar mb-3'>
        {pipelineStages?.length > 0 && (
          <>
            <Dropdown
              show={isFilterOpen}
              onToggle={(isFilterOpen) => setIsFilterOpen(isFilterOpen)}
            >
              <Dropdown.Toggle
                className={`hide-dropdown-toggle-icon btn mx-3 d-flex align-items-center ${
                  filterOption ? 'btn-primary' : 'btn-light-primary'
                }`}
                id='dropdown-invites'
              >
                <i className='fa-solid fa-filter mx-1'></i> Filter
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className='w-300px w-md-325px py-3'>
                  <div className='px-7 py-3'>
                    <h5 className='fw-bolder'>Filter Options</h5>
                  </div>
                  <div className='content-divider'></div>
                  <div className='my-5 px-5'>
                    <Select
                      options={filterOptions}
                      onChange={(option) => setFilterOption(option?.value)}
                      closeMenuOnSelect
                    />
                  </div>
                  <div className='d-end px-5'>
                    <Button
                      variant='light'
                      className='btn-active-light-primary fw-bold me-2 px-6'
                      onClick={handleResetFilter}
                    >
                      Reset
                    </Button>
                    <Button variant='primary' className='fw-bold px-6' onClick={handleApplyFilter}>
                      Apply
                    </Button>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              variant='primary'
              className='d-flex flex-row align-items-center'
              onClick={() => setShowContractModal(true)}
            >
              <i className='fa-solid fa-add'></i>
              <span className='mx-2'>Contract</span>
            </Button>
          </>
        )}
      </div>
      <DragDropContext
        onDragEnd={handleStageORCardDragEnd}
        onDragStart={(element) => {
          if (element.type === 'card') {
            setIsDraggingCard(true)
          }
        }}
      >
        <div className='h-80vh position-relative'>
          <PipelineStatusCard isDraggingCard={isDraggingCard} />
          <Droppable droppableId='pipelineStages' direction='horizontal' type='stage'>
            {(provided) => (
              <div
                className='d-flex pipeline-view-content overflow-auto'
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {pipelineStages.map((stage: any, columnIndex: number) => (
                  <Draggable key={stage.uuid} draggableId={stage.uuid} index={columnIndex}>
                    {(provided) => (
                      <div
                        className='pipeline-column cards-moving-width p-2 h-fit'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <PipeLineStage
                          stage={stage}
                          pipelineId={pipelineId}
                          pipelineNotificationsResult={pipelineNotificationsResult}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {singlePipelineData?.pipeline && (
                  <div className='w-100 p-2'>
                    <div
                      className='d-flex flex-row rounded align-items-baseline px-4 py-3 cursor-pointer w300'
                      style={{
                        backgroundColor: 'rgb(244, 244, 244)',
                      }}
                      onClick={() => setShowAddStageModal(true)}
                    >
                      <i className='text-black fs-4 fw-bold fa-solid fa-plus'></i>
                      <h4 className='mx-2'>Add Stage</h4>
                    </div>
                  </div>
                )}
                {!pipelineId && (
                  <p className='fw-bold fs-4 text-center w-100'>
                    Create a Pipeline to Get Started!
                  </p>
                )}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      {showContractModal && (
        <PipeLineCardForm
          pipelineId={String(pipelineId)}
          pipelineStages={pipelineStages}
          showCardModal={showContractModal}
          setShowCardModal={setShowContractModal}
        />
      )}
      {showAddStageModal && (
        <PipeLineStageForm
          pipelineId={pipelineId}
          showStageFormModal={showAddStageModal}
          setShowStageFormModal={setShowAddStageModal}
        />
      )}
    </div>
  )
}

export default PipeLineStages

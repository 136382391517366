import React, {useState} from 'react'
import {Link} from 'react-router-dom'

import FeedDetailModal from './FeedDetailModal'
import {useGetFeeds} from '../../../queries/feeds'
import Image from '../../../modules/common/Image'

import '../feeds.css'

const HighlightFeeds = () => {
  const [selectedFeed, setSelectedFeed] = useState<any>(null)
  const [showFeedDetailModal, setShowFeedDetailModal] = useState(false)
  const highlightedFeedsResult = useGetFeeds({isHighlighted: true})
  const highlightedFeeds = highlightedFeedsResult.data?.feeds

  const handleFeedClick = (event: React.MouseEvent, feed: any) => {
    event.preventDefault()
    setSelectedFeed(feed)
    setShowFeedDetailModal(true)
  }

  return (
    <>
      <div className='w-100 rounded p-5 card-moving' style={{backgroundColor: 'rgb(239,246,255)'}}>
        <h3 className='text-primary'>Trending Posts (7-Days)</h3>
        <div className='mt-7 w-100'>
          {highlightedFeeds?.length === 0 && (
            <i className='fs-5 text-start text-gray'>No Record Found!</i>
          )}
          {highlightedFeeds?.map((feed: any, index: number) => (
            <div key={index} className='d-flex align-item-center mb-2'>
              <Image
                src={`${feed.user?.absoluteProfilePath}`}
                className='mb-4 img-fluid img-round'
              />
              <div className='mx-4'>
                <p
                  className={`fs-5 text-start fw-bold feed-title-highlighter mb-0`}
                  onClick={(event) => handleFeedClick(event, feed)}
                >
                  {feed.title.substring(0, 30)}
                </p>
                <Link
                  to={`/view-profile/${feed.user?.id}`}
                  className='menu-link card-title fw-bold text-muted'
                >
                  <p>
                    {feed.user?.firstName} {feed.user?.lastName}
                  </p>
                </Link>
              </div>
            </div>
          ))}
        </div>

        {showFeedDetailModal && (
          <FeedDetailModal
            feed={selectedFeed}
            showFeedDetailModal={showFeedDetailModal}
            handleModalClose={() => setShowFeedDetailModal(false)}
          />
        )}
      </div>
    </>
  )
}

export default HighlightFeeds

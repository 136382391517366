import React, {ReactNode} from 'react'
import {Modal, Button} from 'react-bootstrap'

interface GenericModalProps {
  title: string
  subtitle?: string
  showModal: boolean
  disableBtns?: boolean
  onConfirm?: () => void
  onHideModal: () => void
  showConfirmBtn?: boolean
  hideHeader?: boolean
  hideFooter?: boolean
  backdrop?: 'static' | true | false
  modalProps?: any // pass bootstrap modal props in object
  children: ReactNode
}

const GenericModal: React.FC<GenericModalProps> = ({
  showModal,
  onConfirm,
  onHideModal,
  disableBtns,
  title,
  children,
  hideHeader,
  hideFooter,
  showConfirmBtn,
  backdrop = 'static',
  modalProps = {},
}) => {
  return (
    <Modal show={showModal} onHide={onHideModal} {...modalProps} backdrop={backdrop}>
      {!hideHeader && (
        <Modal.Header className='py-4' closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className='py-4'>{children}</Modal.Body>
      {!hideFooter && (
        <Modal.Footer>
          <Button variant='secondary' disabled={disableBtns} onClick={onHideModal}>
            Cancel
          </Button>
          {showConfirmBtn && (
            <Button variant='danger' onClick={onConfirm} disabled={disableBtns}>
              Confirm
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  )
}

export default GenericModal

import React, {useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import moment from 'moment'
import parse from 'html-react-parser'

import Image from '../../../modules/common/Image'
import CustomDropdown from '../../../modules/common/CustomDropDown'
import {useDeletePipelineStageCard} from '../../../queries/pipelines'
import PipeLineCardForm from './PipeLineCardForm'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import {useAuth} from '../../../modules/auth'
import Attachments from '../../feeds/components/FeedAttachments'
import PipelineCardCommentForm from './PipelineCardCommentForm'
import PipelineCardComments from './PipelineCardComments'

interface PipeLineCardDetailProps {
  showPipeLineDetailModal: boolean
  setShowPipeLineDetailModal: (show: boolean) => void
  card: any
  pipelineId: string
  stageId: string
  pipelineNotificationsResult?: any
}

const PipeLineCardDetail: React.FC<PipeLineCardDetailProps> = ({
  showPipeLineDetailModal,
  setShowPipeLineDetailModal,
  card,
  pipelineId,
  stageId,
  pipelineNotificationsResult,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditCardModal, setShowEditCardModal] = useState(false)
  const [showDescription, setShowDescription] = useState(
    card?.description?.length > 500 ? false : true
  )
  const {mutateAsync: deleteCard} = useDeletePipelineStageCard()
  const {currentUser} = useAuth()

  const handleDeletePipeLine = async () => {
    try {
      await deleteCard({pipelineId: pipelineId, stageId: stageId, cardId: card.uuid})
      setShowDeleteModal(false)
    } catch (error) {
      console.error(error)
    }
  }

  const confirmDeletePipeLine = () => {
    setShowDeleteModal(true)
  }

  return (
    <>
      <Modal
        show={showPipeLineDetailModal}
        onHide={() => setShowPipeLineDetailModal(false)}
        className={`${showEditCardModal && 'd-none'}`}
        size='lg'
        centered
      >
        <Modal.Header closeButton className='feed-detail-header'>
          <div className='d-flex justify-content-between align-items-center w-100 mx-2'>
            <div className='d-flex align-items-start'>
              <Image src={card?.user?.absoluteProfilePath} />
              <div className='ms-2'>
                <Modal.Title>
                  {card?.user?.firstName} {card?.user?.lastName}
                </Modal.Title>
                <p className='text-gray'>Created {moment(card?.createdAt).fromNow()}</p>
              </div>
            </div>
            {currentUser?.id === card?.user.id && (
              <div className='d-flex justify-content-end'>
                <CustomDropdown
                  className='bg-secondary px-2 py-1 rounded'
                  elementId={'pipelineStageCard'}
                  options={[
                    {
                      label: (
                        <>
                          <i className='fa-solid fs-7 fa-pencil text-muted'></i>
                          <span className='mx-2 text-muted'>Edit</span>
                        </>
                      ),
                      handler: () => setShowEditCardModal(true),
                    },
                    {
                      label: (
                        <>
                          <i className='fa-solid fs-7 text-danger fa-trash'></i>
                          <span className='mx-2 text-danger'>Archive</span>
                        </>
                      ),
                      handler: () => confirmDeletePipeLine(),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <h1>{card.name}</h1>
          <div>
            <p
              className={`mb-2 fw-medium fs-6 badge text-light cursor-default ${
                moment(card.dueDate).endOf('day').isBefore(moment())
                  ? 'text-bg-danger'
                  : 'text-bg-primary'
              }`}
              title={
                card.dueDate && moment(card.dueDate).endOf('day').isBefore(moment())
                  ? 'Overdue'
                  : 'Due date'
              }
            >
              <span className='fa-solid fa-clock me-1'></span>
              <span>{card.dueDate && moment(card.dueDate).format('DD MMM')}</span>
            </p>
            <span
              className={`fw-medium fs-6 ms-2 badge text-light cursor-pointer ${
                card.status === 'inProgress' && 'text-bg-primary'
              } ${card.status === 'won' && 'text-bg-success'} ${
                card.status === 'lost' && 'text-bg-danger'
              } ${card.status === 'noBid' && 'text-bg-dark'} ${
                card.status === 'submitted' && 'text-bg-info'
              }`}
            >
              {card.status.charAt(0).toUpperCase() + card.status.slice(1)}
            </span>
          </div>

          <div className='d-flex align-items-baseline gap-2 flex-wrap'>
            <p title='Contract Location' className='mb-1'>
              <i className='fa-solid fa-location-dot fs-6 me-1'></i>
              {card.contractLocation}
            </p>
            {card?.preBidDate && (
              <span className='mb-2 ms-2 fs-6 cursor-default' title={'Pre Bid Date'}>
                <i className='fa-solid fa-clock me-1'></i>
                <span>{moment(card.preBidDate).format('DD MMM')}</span>
              </span>
            )}
            {card?.questionDeadline && (
              <span className='mb-2 ms-2 fs-6 cursor-default' title={'Questions Deadline Date'}>
                <i className='fa-solid fa-clock me-1'></i>
                <span>{moment(card.questionDeadline).format('DD MMM')}</span>
              </span>
            )}

            {card?.contractValue && (
              <span className='mb-2 ms-2 fs-6 cursor-default' title='Contract Value'>
                <i className='fa-solid fa-dollar-sign me-1 fs-5'></i>
                {card.contractValue}
              </span>
            )}
            {card?.nextStep && (
              <span title='Next Step' className={`${card?.contractValue && 'ms-1 mb-1'}`}>
                <i className='fas fa-arrow-circle-right fs-6 me-1'></i>
                {card.nextStep}
              </span>
            )}
          </div>

          <div className='d-flex align-items-baseline gap-2'></div>

          <p title={card.workScope}>
            <strong className='me-1'>Scope:</strong>
            {parse(card.workScope)}
          </p>

          {card?.description && (
            <>
              {!showDescription && <p>{parse(`${card?.description?.slice(0, 500)}...`)}</p>}
              {showDescription && <p>{parse(card?.description)}</p>}
              {card?.description && card?.description?.length > 500 && (
                <Button
                  variant='secondary'
                  onClick={() => {
                    if (!showDescription) {
                      setShowDescription(true)
                    } else {
                      setShowDescription(false)
                    }
                  }}
                  className='border-0 text-dark text-start text-sm px-2 py-1'
                >
                  {showDescription ? 'Read less' : 'Read more'}
                </Button>
              )}
            </>
          )}

          {card.attachments?.length > 0 && (
            <div className='mt-5'>
              <h3>Attachments:</h3>
              <Attachments attachments={card.attachments} hideDeleteIcon={false} />
            </div>
          )}

          <div>
            <PipelineCardComments
              pipelineId={pipelineId}
              stageId={stageId}
              cardId={card.uuid}
              pipelineNotificationsResult={pipelineNotificationsResult}
            />
          </div>
          <div className='mt-5'>
            <PipelineCardCommentForm pipelineId={pipelineId} stageId={stageId} cardId={card.uuid} />
          </div>
        </Modal.Body>
      </Modal>

      {showEditCardModal && (
        <PipeLineCardForm
          pipelineId={pipelineId}
          stageId={stageId}
          setShowCardModal={setShowEditCardModal}
          showCardModal={showEditCardModal}
          stagesCardValues={card}
          setShowPipeLineDetailModal={setShowPipeLineDetailModal}
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          showModal={showDeleteModal}
          onHideModal={() => setShowDeleteModal(false)}
          onCancel={() => setShowDeleteModal(false)}
          title='Archive Contract'
          subtitle='Are you sure you want to archive this Contract?'
          onConfirm={handleDeletePipeLine}
          disableBtns={false}
        />
      )}
    </>
  )
}

export default PipeLineCardDetail

import React, {useState} from 'react'

import {FormatedDateDifferenceComment} from '../../../modules/common/utils'
import FeedCommentsForm from './FeedCommentsForm'
import RecentFeedLikes from './RecentFeedLikes'
import Attachments from './FeedAttachments'
import {useDeleteFeedComment} from '../../../queries/feedComments'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import Image from '../../../modules/common/Image'

interface CommentRowProps {
  comment: any
  index: string
  isReply?: boolean
  currentUserId: any
  toggleReplyField: any
  commentsEnabled: boolean
}

const RenderCommentRow: React.FC<CommentRowProps> = ({
  comment,
  index,
  isReply = false,
  currentUserId,
  toggleReplyField,
  commentsEnabled,
}) => {
  const [editingIndex, setEditingIndex] = useState<string | null>(null)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const deleteCommentMutation = useDeleteFeedComment()

  const editComment = (index: any) => {
    setEditingIndex(index)
  }

  const deleteComment = async (comment: any) => {
    try {
      await deleteCommentMutation.mutateAsync({commentId: comment.id, feedId: comment.feedId})
      setShowConfirmationModal(false)
    } catch {}
  }

  const formatComment = (comment: string) => {
    const comments = comment?.split(' ')
    return comments.map((comment, index) => {
      if (comment.startsWith('http://') || comment.startsWith('https://')) {
        return (
          <a
            className='comments-link'
            key={index}
            href={comment}
            target='_blank'
            rel='noopener noreferrer'
          >
            {index === 0 ? comment : ` ${comment}`}
          </a>
        )
      } else {
        return <span key={index}>{index === 0 ? comment : ` ${comment}`}</span>
      }
    })
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const renderCommentContent = () => {
    if (comment.comment?.length > 200) {
      if (isExpanded) {
        return (
          <>
            <p className='m-0 p-0'>{formatComment(comment.comment)}</p>
            <button onClick={toggleExpand} className='btn text-primary p-0'>
              Read Less
            </button>
          </>
        )
      } else {
        return (
          <>
            <p className='m-0 p-0'>{formatComment(comment.comment.slice(0, 200).concat('...'))}</p>
            <button onClick={toggleExpand} className='btn text-primary p-0'>
              Read More
            </button>
          </>
        )
      }
    } else {
      return formatComment(comment.comment)
    }
  }

  return (
    <div
      className='comment'
      style={{
        marginLeft: isReply ? '60px' : '0px',
      }}
      key={index}
    >
      <div className='d-flex my-3 align-items-start position-relative'>
        <Image src={`${comment.user?.absoluteProfilePath}`} />
        <div
          style={{border: '1px solid #cdcdcd'}}
          className='mx-3 py-3 overflow-x-scroll w-100 bg-light rounded'
        >
          <p className='my-0 mx-3'>
            {comment.user && `${comment.user.firstName} ${comment.user.lastName}`}
            <span style={{marginLeft: '5px'}} className='text-gray'>
              {comment && comment.createdAt && FormatedDateDifferenceComment(comment.createdAt)}
            </span>
          </p>
          <div className='w-100'>
            {editingIndex === index ? (
              <div className='d-flex ml10'>
                <FeedCommentsForm
                  feedId={comment.feedId}
                  setEditingIndex={setEditingIndex}
                  editedComment={comment}
                />
              </div>
            ) : (
              <>
                <div className='my-1 mx-3'>{renderCommentContent()}</div>
                <div className='d-flex align-items-center overflow-x-scroll'>
                  <Attachments attachments={comment._attachments} hideDeleteIcon={false} />
                </div>
              </>
            )}
          </div>
        </div>
        {currentUserId === comment.user.id && !editingIndex && (
          <div className='dropdown position-absolute top-0 end-0 mt-2 me-2'>
            <button
              className='btn btn-transparent text-gray'
              type='button'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              <i className='fa-solid fs-2 feed-menu-icon fa-ellipsis'></i>
            </button>
            <ul className='dropdown-menu'>
              <li className='dropdown-item cursor-pointer' onClick={() => editComment(index)}>
                <i className='fa fa-pencil me-2'></i> Edit
              </li>
              <li
                onClick={() => setShowConfirmationModal(true)}
                className='dropdown-item cursor-pointer'
              >
                <i className='fa fa-trash me-2'></i> Delete
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className='d-flex flex-row align-items-center' style={{marginLeft: '60px'}}>
        <div>
          <RecentFeedLikes
            likes={comment?.feedCommentLikes}
            currentUserId={currentUserId}
            commentId={comment.id}
            feedId={comment.feedId}
          />
        </div>
        <div>
          {!isReply && commentsEnabled && (
            <button
              className='btn btn-outline-secondary btn-sm text-gray'
              onClick={() => toggleReplyField(index)}
            >
              Reply
            </button>
          )}
        </div>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          showModal={showConfirmationModal}
          onHideModal={() => setShowConfirmationModal(false)}
          disableBtns={false}
          onConfirm={() => deleteComment(comment)}
          onCancel={() => setShowConfirmationModal(false)}
        />
      )}
    </div>
  )
}

export default RenderCommentRow

import {useState} from 'react'
import {Card, Col, ProgressBar} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'

import CustomDropDown from '../../../modules/common/CustomDropDown'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import CoursesForm from './CoursesForm'
import {useDeleteCourse} from '../../../queries/courses'
import {useAuth} from '../../../modules/auth'

const CourseItem = ({
  course,
  totalCourses,
  updateCourseOrder,
}: {
  course: any
  totalCourses: number
  updateCourseOrder: any
}) => {
  const navigate = useNavigate()
  const {selectedCommunity} = useAuth()
  const {mutateAsync: deleteCourse, isPending: isDeletingCourse} = useDeleteCourse()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditCourseModal, setShowEditCourseModal] = useState(false)

  const handleDeleteCourse = async () => {
    try {
      await deleteCourse(course.uuid)
      navigate('/courses')
      setShowDeleteModal(false)
    } catch {}
  }

  const handleMoveCourse = async (newOrder: number) => {
    try {
      await updateCourseOrder({courseId: course.uuid, order: newOrder})
      navigate('/courses')
    } catch {}
  }

  const courseOptions = [{label: 'Edit Course', handler: () => setShowEditCourseModal(true)}]
  if (course.order < totalCourses) {
    courseOptions.push({label: 'Move ->', handler: () => handleMoveCourse(course.order + 1)})
  }
  if (course.order > 1) {
    courseOptions.push({label: 'Move <-', handler: () => handleMoveCourse(course.order - 1)})
  }
  if (selectedCommunity?.permissions.canDeleteCourse) {
    courseOptions.push({label: 'Delete Course', handler: () => setShowDeleteModal(true)})
  }

  const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL

  return (
    <Col key={course.id} md={6} sm={12} lg={4} className='mb-5 course-item'>
      <Card
        className={`card-course cursor-pointer ${course.status === 'draft' ? 'draft-course' : ''}`}
      >
        <div onClick={() => navigate(`/courses/detail/${course.uuid}`)}>
          <Card.Img
            variant='top'
            style={{height: '200px'}}
            className='w-100 object-center object-cover'
            src={`${
              course.coverPic ? `${s3BaseUrl}${course.coverPic}` : '/media/misc/menu-header-bg.jpg'
            }`}
          />
          <Card.Body>
            <Card.Title>{course.title}</Card.Title>
            <Card.Text style={{maxHeight: '33px', minHeight: '33px'}}>
              {course.description.substring(0, 60)}
            </Card.Text>
            {selectedCommunity?.isMember && (
              <ProgressBar
                now={course.courseProgress}
                className='text-black'
                variant={course.courseProgress < 100 ? 'primary' : 'success'}
                animated={course.courseProgress < 100 ? true : false}
                striped={course.courseProgress < 100 ? true : false}
                label={`${course.courseProgress}%`}
                style={{height: '20px'}}
              />
            )}
            <button className='btn btn-light w-100 mt-9' style={{border: '1px solid #d7d7d7'}}>
              <span className='text-dark'>OPEN</span>
            </button>
          </Card.Body>
        </div>
        {course.status === 'draft' && (
          <div
            className='position-absolute bg-dark p-4 rounded-circle'
            style={{top: '10px', left: '10px'}}
          >
            <span className='text-white'>Draft</span>
          </div>
        )}
        {selectedCommunity?.permissions.canCreateEditCourse && (
          <div className={`CustomDropDownItem rounded-circle bg-white`}>
            <CustomDropDown elementId='module-dropdown' options={courseOptions} />
          </div>
        )}
      </Card>
      {showEditCourseModal && (
        <CoursesForm
          course={course}
          show={showEditCourseModal}
          handleClose={() => setShowEditCourseModal(false)}
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          showModal={showDeleteModal}
          onHideModal={() => setShowDeleteModal(false)}
          onCancel={() => setShowDeleteModal(false)}
          title='Delete Course'
          subtitle='Are you sure you want to delete this course?'
          onConfirm={handleDeleteCourse}
          disableBtns={isDeletingCourse}
        />
      )}
    </Col>
  )
}

export default CourseItem

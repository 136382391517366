import {Link, useNavigate} from 'react-router-dom'
import {useState} from 'react'
import {Button} from 'react-bootstrap'

import {useAuth} from '../../modules/auth'
import Image from '../../modules/common/Image'
import LeaderBoardActivity from './Components/LeaderBoardActivity'
import UserLevels from './Components/UserLevels'
import {useGetCommunitiesLeaderBoard} from '../../queries/communitySettings'
import GenericModal from '../../modules/common/Modal'
import LevelsInfo from './Components/LevelsInfo'
import FetchingSpinner from '../../modules/common/FetchingSpinner'

import './LeaderBoard.scss'

const LeaderBoardIndex = () => {
  const navigate = useNavigate()
  const {currentUser, selectedCommunity} = useAuth()
  const [showLevelDetailModal, setShowLevelDetailModal] = useState(false)

  const leaderBoardsResult = useGetCommunitiesLeaderBoard()

  if (leaderBoardsResult.isPending) {
    return <FetchingSpinner />
  }

  const communityLeaderBoard = leaderBoardsResult.data || {}
  const {currentUserStats = {}} = communityLeaderBoard

  return (
    <div className='overflow-x-hidden'>
      {selectedCommunity?.isCommunityAdmin && (
        <div
          className='icon-link position-absolute mt-5 mx-6 settings-leaderboard p-3 rounded-circle d-flex align-items-center cursor-pointer'
          onClick={() => navigate('/community-settings?tab=gamification')}
        >
          <i className='bi bi-gear-fill fs-1 text-primary'></i>
        </div>
      )}
      <div className='border border-secondary p-20 leaderboard-stats-container'>
        <div className='w-100 d-flex leaderboard-container'>
          <div className='w-25 text-center user-stats'>
            <Link to={`/view-profile/${currentUser?.id}`}>
              <Image
                src={`${currentUser?.absoluteProfilePath}`}
                className='profile-pic rounded-circle object-cover object-top'
              />
            </Link>
            <div className='position-relative badge-leaderboard'>
              <i className='bi bi-shield-fill text-primary' style={{fontSize: '40px'}}></i>
              <h3 className='text position-relative text-white level-number'>
                {currentUserStats.currentLevel?.levelNumber}
              </h3>
            </div>
            <div className='mt-2'>
              <Link to={`/view-profile/${currentUser?.id}`} className='fs-1 text-black fw-bold'>
                {currentUser?.firstName} {currentUser?.lastName}
              </Link>
              <p className='text-primary fs-6 fw-bold mt-2'>
                {currentUserStats?.currentLevel?.name}
              </p>
              <div className='d-flex flex-row justify-content-center align-items-center'>
                <p className='fs-6'>
                  <strong>{currentUserStats?.pointsRequiredForNextLevel}</strong> points to Level up
                </p>
                <Button
                  variant='transparent'
                  className='border-0 p-0'
                  style={{marginTop: '-12px'}}
                  onClick={() => setShowLevelDetailModal(true)}
                >
                  <i className='fa-regular mx-3 fs-5 fa-circle-question'></i>
                </Button>
              </div>
            </div>
          </div>
          <div>
            <UserLevels
              levelDistribution={communityLeaderBoard?.levelDistribution}
              currentLevel={currentUserStats?.currentLevel}
            />
          </div>
        </div>
      </div>
      <div className='d-flex column-gap-9 leaderboard-activity-container my-4'>
        <LeaderBoardActivity
          title='LeaderBoard (7-day)'
          leaderBoardStats={communityLeaderBoard?.sevenDayLeaderboard}
        />
        <LeaderBoardActivity
          title='LeaderBoard (30-day)'
          leaderBoardStats={communityLeaderBoard?.monthlyLeaderboard}
        />
        <LeaderBoardActivity
          title='LeaderBoard (all-time)'
          leaderBoardStats={communityLeaderBoard?.allTimeLeaderboard}
        />
      </div>
      <GenericModal
        hideHeader
        hideFooter
        title=''
        showModal={showLevelDetailModal}
        onHideModal={() => setShowLevelDetailModal(false)}
        backdrop={true}
      >
        <LevelsInfo communityName={selectedCommunity?.name} />
      </GenericModal>
    </div>
  )
}

export default LeaderBoardIndex

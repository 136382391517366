import {FC} from 'react'
import {Button} from 'react-bootstrap'
import Image from '../../../modules/common/Image'

import './Users.css'

interface UserDetailProps {
  userDetail: any
  setShowChatModal: (show: boolean) => void
}

const UserDetail: FC<UserDetailProps> = ({userDetail, setShowChatModal}) => {
  return (
    <>
      {userDetail && (
        <div className='card right-card px-5 py-4 h-fit mw-100'>
          <div className='right-card-header text-center'>
            <Image src={`${userDetail.absoluteProfilePath}`} />
            <div className='ms-2'>
              <p className='my-2 fw-bold'>{userDetail.fullName}</p>
              <p className='text-dark fs-6 m-0'>{userDetail.companyName}</p>
            </div>
          </div>
          <div className='my-3 right-card-icons'>
            <div className='d-flex px-2 align-items-baseline'>
              <i className='fa-solid mr20 fa-user fs-6'></i>
              <p className='text-dark fs-6'>{userDetail.userType}</p>
            </div>
            <div className='d-flex px-2 align-items-baseline'>
              <i className='fa-solid mr20 fa-envelope fs-6'></i>
              <p className='text-dark fs-6' style={{wordBreak: 'break-word'}}>
                {userDetail.email}
              </p>
            </div>
            <div className='d-flex px-2 align-items-baseline'>
              <i className='fa-solid mr20 fa-location-dot fs-6'></i>
              <p className='text-dark fs-6'>{userDetail.state ? userDetail.state : 'N/A'}</p>
            </div>
            <div className='d-flex px-2 align-items-baseline'>
              <i className='fa-solid mr20 fa-circle-info fs-6'></i>
              <p className='text-dark fs-6'>{userDetail.bio ? userDetail.bio : 'N/A'}</p>
            </div>
          </div>

          <div className='rounded'>
            <Button variant='primary' className='w-100' onClick={() => setShowChatModal(true)}>
              Start new chat
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default UserDetail

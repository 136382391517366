import {useState} from 'react'
import {Badge, Table} from 'react-bootstrap'

import {ConfirmationModal} from '../../modules/common/ConfirmationModal'
import {useDeleteTeamMember, useResendInvite} from '../../queries/userTeam'
import CustomDropdown from '../../modules/common/CustomDropDown'
import Image from '../../modules/common/Image'

function MembersList({members}: {members: any[]}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [memberToDelete, setMemberToDelete] = useState<any>(null)
  const {mutateAsync: deleteTeamMember} = useDeleteTeamMember()
  const {mutateAsync: resendInvite} = useResendInvite()

  const handleDelete = async () => {
    try {
      if (memberToDelete) {
        await deleteTeamMember(memberToDelete)
        setShowDeleteModal(false)
      }
    } catch (error) {}
  }

  const handleResendInvite = async (inviteId: number) => {
    try {
      await resendInvite(inviteId)
    } catch (error) {}
  }

  return (
    <div className='py-2'>
      <Table hover striped bordered>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {members?.map((member) => (
            <tr key={member.id}>
              <td>
                <Image src={member.user?.absoluteProfilePath || '/media/avatars/blank.png'} />
                <span className='ms-2'>{member.user?.fullName}</span>
              </td>
              <td>{member.email}</td>
              <td>{member.role}</td>
              <td>
                <Badge
                  bg={member.invitationStatus === 'accepted' ? 'secondary' : 'danger'}
                  className={`${
                    member.invitationStatus === 'pending' ? 'text-white' : 'text-primary'
                  } `}
                >
                  {member.invitationStatus}
                </Badge>
              </td>
              <td>
                <CustomDropdown
                  elementId={'team-member'}
                  options={[
                    {
                      label: (
                        <>
                          <i className='fa-solid fa-trash fs-4 text-danger cursor-pointer'></i>
                          <span className='mx-2 text-danger'>Delete</span>
                        </>
                      ),
                      handler: () => {
                        setShowDeleteModal(true)
                        setMemberToDelete(member.id)
                      },
                    },
                    ...(member.invitationStatus !== 'accepted'
                      ? [
                          {
                            label: (
                              <>
                                <i className='fa-solid fa-paper-plane text-black'></i>
                                <span className='mx-2'>Resend Invite</span>
                              </>
                            ),
                            handler: () => handleResendInvite(member.id),
                          },
                        ]
                      : []),
                  ]}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {showDeleteModal && (
        <ConfirmationModal
          showModal={showDeleteModal}
          onHideModal={() => setShowDeleteModal(false)}
          onCancel={() => setShowDeleteModal(false)}
          title='Delete Member'
          subtitle='Confirm you want to remove this member?'
          onConfirm={handleDelete}
          disableBtns={false}
        />
      )}
    </div>
  )
}

export default MembersList

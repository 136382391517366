import {Card} from 'react-bootstrap'
import {useEffect, useState} from 'react'
import moment from 'moment'
import parse from 'html-react-parser'

import PipeLineCardDetail from './PipeLineCardDetail'
import {useReadPipelineNotification} from '../../../queries/notifications'

const PipeLineCard = ({
  card,
  pipelineId,
  stageId,
  pipelineNotificationsResult,
}: {
  card: any
  pipelineId: string
  stageId: string
  pipelineNotificationsResult: any
}) => {
  const [showPipeLineDetailModal, setShowPipeLineDetailModal] = useState(false)
  const readPipelineNotifications = useReadPipelineNotification()
  const hasCardCommentNotification = pipelineNotificationsResult?.some(
    (notification: any) =>
      notification.meta?.type === 'PIPELINE_NEW_COMMENT' &&
      notification.meta?.pipelineId === pipelineId &&
      notification.meta?.pipelineCardId === card.uuid &&
      !notification.readStatus
  )

  useEffect(() => {
    if (showPipeLineDetailModal) {
      setTimeout(() => {
        const unreadNotifications = pipelineNotificationsResult?.filter(
          (notification: any) =>
            notification.meta?.type === 'PIPELINE_NEW_COMMENT' &&
            notification.meta?.pipelineId === pipelineId &&
            notification.meta?.pipelineCardId === card.uuid &&
            !notification.readStatus
        )

        const notificationIds = unreadNotifications.map((notification: any) => notification.id)
        if (notificationIds.length) {
          readPipelineNotifications.mutate({notificationIds: notificationIds})
        }
      }, 2000)
    }
  }, [showPipeLineDetailModal])

  return (
    <Card className='stage-card cursor-pointer' style={{backgroundColor: '#f4f4f4'}}>
      <Card.Body className='px-5 py-4' onClick={() => setShowPipeLineDetailModal(true)}>
        <Card.Title>
          {card.name}
          {hasCardCommentNotification && <span className='notification-indicator top-1'></span>}
        </Card.Title>
        <div className='d-flex gap-3 align-items-baseline'>
          <div>
            <p
              title={
                card.dueDate && moment(card.dueDate).endOf('day').isBefore(moment())
                  ? 'Overdue'
                  : 'Due date'
              }
              className={`mb-2 fw-medium fs-6 badge text-light cursor-pointer ${
                moment(card.dueDate).endOf('day').isBefore(moment())
                  ? 'text-bg-danger'
                  : 'text-bg-primary'
              }`}
            >
              <span className='fa-solid fa-clock me-1'></span>
              <span>{card.dueDate && moment(card.dueDate).format('DD MMM')}</span>
            </p>
          </div>
          <span
            className={`fw-medium fs-6 badge text-light cursor-pointer ${
              card.status === 'inProgress' && 'text-bg-primary'
            } ${card.status === 'won' && 'text-bg-success'} ${
              card.status === 'lost' && 'text-bg-danger'
            } ${card.status === 'noBid' && 'text-bg-dark'} ${
              card.status === 'submitted' && 'text-bg-info'
            }`}
          >
            {card.status.charAt(0).toUpperCase() + card.status.slice(1)}
          </span>
        </div>
        <div className='d-flex align-items-baseline'>
          {card?.contractValue && (
            <Card.Text title='Contract Value' className='mb-2 me-2'>
              <i className='fa-solid fa-dollar-sign me-1 fs-6'></i>
              {card.contractValue}
            </Card.Text>
          )}
          <Card.Text title='Contract Location'>
            <i className='fa-solid fa-location-dot fs-6 me-1'></i>
            {card.contractLocation}
          </Card.Text>
        </div>

        {card?.nextStep && (
          <Card.Text title='Next Step' className='mb-2'>
            <i className='fas fa-arrow-circle-right fs-6 me-1'></i>
            {card.nextStep}
          </Card.Text>
        )}

        <Card.Text title={card?.workScope}>
          <strong className='me-1'>Scope:</strong>
          {parse(
            card?.workScope?.length > 35
              ? card?.workScope?.slice(0, 35).concat('...')
              : card?.workScope || ''
          )}
        </Card.Text>
      </Card.Body>

      {showPipeLineDetailModal && (
        <PipeLineCardDetail
          showPipeLineDetailModal={showPipeLineDetailModal}
          setShowPipeLineDetailModal={setShowPipeLineDetailModal}
          card={card}
          pipelineNotificationsResult={pipelineNotificationsResult}
          pipelineId={pipelineId}
          stageId={stageId}
        />
      )}
    </Card>
  )
}

export default PipeLineCard

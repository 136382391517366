import React, {ReactNode} from 'react'
import {Offcanvas, Button} from 'react-bootstrap'

interface GenericOffcanvasProps {
  title?: any
  subtitle?: string
  showOffcanvas: boolean
  disableBtns?: boolean
  onConfirm?: () => void
  onHideOffcanvas: () => void
  showConfirmBtn?: boolean
  hideHeader?: boolean
  hideFooter?: boolean
  backdrop?: boolean
  placement?: 'start' | 'end' | 'top' | 'bottom'
  offcanvasProps?: any
  children: ReactNode
}

const GenericOffcanvas: React.FC<GenericOffcanvasProps> = ({
  showOffcanvas,
  onConfirm,
  onHideOffcanvas,
  disableBtns,
  title,
  children,
  hideHeader,
  hideFooter,
  showConfirmBtn,
  backdrop = true,
  placement = 'start',
  offcanvasProps = {},
}) => {
  return (
    <Offcanvas
      show={showOffcanvas}
      onHide={onHideOffcanvas}
      backdrop={backdrop}
      placement={placement}
      {...offcanvasProps}
    >
      {!hideHeader && (
        <Offcanvas.Header closeButton className={`${title && 'py-3'}`}>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
      )}
      <Offcanvas.Body>{children}</Offcanvas.Body>
      {!hideFooter && (
        <div className='offcanvas-footer'>
          {showConfirmBtn && (
            <Button variant='danger' onClick={onConfirm} disabled={disableBtns}>
              Confirm
            </Button>
          )}
        </div>
      )}
    </Offcanvas>
  )
}

export default GenericOffcanvas

import React, {useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'

import CustomDropdown from '../../../modules/common/CustomDropDown'
import PipeLineForm from './PipeLineForm'
import GenericModal from '../../../modules/common/Modal'
import {ConfirmationModal} from '../../../modules/common/ConfirmationModal'
import {useDeletePipeline} from '../../../queries/pipelines'
import SharePipeline from './SharePipeline'
import {useReadPipelineNotification} from '../../../queries/notifications'

const PipelineItem = ({
  pipeline,
  isSharedPipeline,
  pipelineNotificationsResult,
}: {
  pipeline: any
  isSharedPipeline?: boolean
  pipelineNotificationsResult: any
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const pipelineId = searchParams.get('pipelineId')
  const {mutateAsync: deletePipeline} = useDeletePipeline()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showPipeLineModal, setShowPipeLineModal] = useState(false)
  const [sharePipeline, setSharePipeline] = useState(false)
  const readPipelineNotifications = useReadPipelineNotification()

  const hasSharedPipelineNotification = pipelineNotificationsResult?.some(
    (notification: any) =>
      notification.meta?.type === 'PIPELINE_SHARED' &&
      notification.meta?.pipelineId === pipeline.uuid &&
      !notification.readStatus
  )

  const hasCardCommentNotification = pipelineNotificationsResult?.some(
    (notification: any) =>
      notification.meta?.type === 'PIPELINE_NEW_COMMENT' &&
      notification.meta?.pipelineId === pipeline.uuid &&
      !notification.readStatus
  )

  const handleDeletePipeLine = async () => {
    try {
      await deletePipeline(pipeline.uuid)
    } catch (error) {
      console.error(error)
    } finally {
      setShowDeleteModal(false)
      navigate('/pipeline')
    }
  }

  const confirmDeletePipeLine = () => {
    setShowDeleteModal(true)
  }

  const dropdownOptions = [
    {
      label: (
        <>
          <i className='fa-solid fs-7 fa-pencil'></i>
          <span className='mx-2 text-muted'>Edit</span>
        </>
      ),
      handler: () => setShowPipeLineModal(true),
    },
  ]

  if (!isSharedPipeline) {
    dropdownOptions.push(
      {
        label: (
          <>
            <i className='fa-solid fs-7 text-muted fa-share'></i>
            <span className='mx-2 text-muted'>Share</span>
          </>
        ),
        handler: () => setSharePipeline(true),
      },
      {
        label: (
          <>
            <i className='fa-solid fs-7 text-danger fa-trash'></i>
            <span className='mx-2 text-danger'>Archive</span>
          </>
        ),
        handler: () => confirmDeletePipeLine(),
      }
    )
  }

  useEffect(() => {
    if (pipelineId === pipeline.uuid) {
      const filteredNotifications = pipelineNotificationsResult?.filter(
        (notification: any) =>
          notification.meta?.type === 'PIPELINE_SHARED' &&
          notification.meta?.pipelineId === pipeline.uuid &&
          !notification.readStatus
      )

      const notificationIds = filteredNotifications?.map((notification: any) => notification.id)
      if (notificationIds?.length) {
        readPipelineNotifications.mutate({notificationIds: notificationIds})
      }
    }
  }, [pipelineId])

  return (
    <>
      <div
        onClick={() => navigate(`?pipelineId=${pipeline.uuid}`)}
        className={`rounded px-4 py-2 w-100 d-flex flex-row justify-content-between align-items-center position-relative ${
          pipeline.uuid === pipelineId ? 'bg-secondary' : ''
        }`}
      >
        <div className='fs-5 fw-bold' title={pipeline.name}>
          {pipeline.name.length > 15 ? pipeline.name.slice(0, 15).concat('...') : pipeline.name}
          {(hasSharedPipelineNotification || hasCardCommentNotification) && (
            <span className={`notification-indicator`}></span>
          )}
        </div>
        <div>
          <CustomDropdown elementId={'pipeline'} options={dropdownOptions} />
        </div>
      </div>
      {showPipeLineModal && (
        <GenericModal
          title={'Edit Pipeline'}
          showModal={showPipeLineModal}
          onHideModal={() => setShowPipeLineModal(false)}
          hideFooter={true}
          modalProps={{
            size: 'md',
            centered: true,
          }}
        >
          <PipeLineForm setShowPipeLineModal={setShowPipeLineModal} pipelineValues={pipeline} />
        </GenericModal>
      )}
      {showDeleteModal && (
        <ConfirmationModal
          showModal={showDeleteModal}
          onHideModal={() => setShowDeleteModal(false)}
          onCancel={() => setShowDeleteModal(false)}
          title='Archive PipeLine'
          subtitle='Are you sure you want to archive this PipeLine?'
          onConfirm={handleDeletePipeLine}
          disableBtns={false}
        />
      )}
      {sharePipeline && (
        <GenericModal
          title={`Share "${pipeline.name}"`}
          showModal={sharePipeline}
          onHideModal={() => setSharePipeline(false)}
          hideFooter={true}
          modalProps={{
            size: 'md',
            centered: true,
          }}
        >
          <SharePipeline pipeline={pipeline} setSharePipeline={setSharePipeline} />
        </GenericModal>
      )}
    </>
  )
}

export default PipelineItem

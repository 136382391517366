import React, {useState, useRef, ChangeEvent, useEffect} from 'react'
import {Editor} from '@tinymce/tinymce-react'

import apiClient from '../../apis/apiClient'
import InsertLinkModal from '../common/InsertLinkModal'
import {useAuth} from '../auth/core/Auth'
import EmojiPicker from 'emoji-picker-react'

interface EditorProps {
  resourceType: string
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>
  isUploading?: boolean
  onFileUpload: (result: any) => void
  initialValue?: any
  name?: any
  handleChange?: any
  value?: any
}

const EditorIndex: React.FC<EditorProps> = ({
  resourceType,
  setIsUploading,
  onFileUpload,
  initialValue,
  name,
  handleChange,
  value,
}) => {
  const editorRef = useRef<any>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [showLinkModal, setShowLinkModal] = useState(false)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const emojiPickerRef = useRef<HTMLDivElement | null>(null)
  const {selectedCommunity} = useAuth()

  const handleLinkClose = () => setShowLinkModal(false)
  const handleVideoClose = () => setShowVideoModal(false)

  const handleCustomUpload = () => {
    // Trigger the file upload in the FileUploader component
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleCustomLinkUpload = () => {
    setShowLinkModal(true)
  }

  const handleCustomVideoUpload = () => {
    setShowVideoModal(true)
  }

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || ''

    // Set isUploading to true using the prop updater
    setIsUploading(true)

    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('resourceType', resourceType)
      const response = await apiClient.post(
        `c/${selectedCommunity?.uuid}/resources/upload-file`,
        formData
      )

      const result = response.data?.data

      // Call the callback function with the uploaded file information
      onFileUpload(result)
    } catch (error) {
      console.error('Error uploading file:', error)
    } finally {
      // Set isUploading to false using the prop updater after the upload is complete (success or failure)
      setIsUploading(false)
    }
  }

  const onLinkInsert = (url: string) => {
    handleChange({
      target: {
        name: 'description',
        value: `${value}<p><a href="${url}" target="_blank">${url}</a></p>`,
      },
    })
  }
  const onVideoInsert = (url: string) => {
    onFileUpload({
      fileType: 'media',
      originalFileName: url,
      signedFileName: url,
    })
  }

  const onEmojiClick = (emojiObject: any) => {
    if (editorRef.current) {
      editorRef.current.insertContent(emojiObject.emoji)
      setShowEmojiPicker(false)
    }
  }

  const handleCustomEmojiButton = () => {
    setShowEmojiPicker(!showEmojiPicker)
  }
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target as Node)) {
        setShowEmojiPicker(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <div className='tiny-editor-wrapper'>
      <Editor
        tinymceScriptSrc={'/tinymce/tinymce.js'}
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={value}
        id={name}
        initialValue={initialValue}
        init={{
          menubar: false,
          min_height: 200,
          height: 200,
          placeholder: 'Write something...',
          plugins: ['autoresize'],
          toolbar:
            'customUploadButton customLinkUploadButton customEmojiButton customVideoUploadButton',
          statusbar: false,
          branding: false,
          toolbar_location: 'bottom',
          content_style: 'body { font-family:Inter, Helvetica, sans-serif; color: #5e6278; }',
          setup: (editor) => {
            editor.ui.registry.addIcon('upload-file', `<i class="bi bi-paperclip fs-2"></i>`)
            editor.ui.registry.addIcon('upload-link', `<i class="bi bi-link fs-2"></i>`)
            editor.ui.registry.addIcon('emoji-smile', `<i class="bi bi-emoji-smile fs-2"></i>`)
            editor.ui.registry.addIcon('upload-media', `<i class="bi bi-youtube fs-2"></i>`)
            editor.ui.registry.addButton('customUploadButton', {
              icon: 'upload-file',
              tooltip: 'Upload File',
              onAction: handleCustomUpload,
            })
            editor.ui.registry.addButton('customLinkUploadButton', {
              icon: 'upload-link',
              tooltip: 'Upload Link',
              onAction: handleCustomLinkUpload,
            })
            editor.ui.registry.addButton('customEmojiButton', {
              icon: 'emoji-smile',
              tooltip: 'Emoji smile',
              onAction: handleCustomEmojiButton,
            })
            editor.ui.registry.addButton('customVideoUploadButton', {
              icon: 'upload-media',
              tooltip: 'Upload Video',
              onAction: handleCustomVideoUpload,
            })
          },
        }}
        onEditorChange={(e) => {
          handleChange({target: {name: 'description', value: e}})
        }}
      />
      <input
        type='file'
        onChange={handleUpload}
        className='d-none'
        ref={fileInputRef}
        accept='image/*, .pdf, .xls, .xlsx, .doc, .docx'
      />
      {showLinkModal && (
        <InsertLinkModal
          handleClose={handleLinkClose}
          showModal={showLinkModal}
          onLinkInsert={onLinkInsert}
        />
      )}
      {showVideoModal && (
        <InsertLinkModal
          handleClose={handleVideoClose}
          showModal={showVideoModal}
          onLinkInsert={onVideoInsert}
          type='video'
        />
      )}
      {showEmojiPicker && (
        <div
          ref={emojiPickerRef}
          className='position-absolute'
          style={{bottom: '120px', left: '15px', zIndex: '10'}}
        >
          <EmojiPicker skinTonesDisabled={true} onEmojiClick={onEmojiClick} height={400} />
        </div>
      )}
    </div>
  )
}

export default EditorIndex

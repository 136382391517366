import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../_metronic/helpers'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  svgIcon?: string
  reloadDocument?: boolean
  titleChildren?: any
  className?: string
}

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  svgIcon,
  hasBullet = false,
  reloadDocument = false,
  titleChildren,
  className,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)

  return (
    <div className={`menu-item ${className}`}>
      <Link
        className={clsx('menu-link without-sub', {active: isActive})}
        to={to}
        reloadDocument={reloadDocument}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {svgIcon && (
          <span className='menu-icon svg-icon'>
            <img src={svgIcon} alt='svg-icon' />
          </span>
        )}
        {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>
          {title}
          {titleChildren}
        </span>
      </Link>
      {children}
    </div>
  )
}

export {AsideMenuItem}

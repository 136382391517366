import {useRef, useState} from 'react'
import {Button} from 'react-bootstrap'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {useUpdateCommunities} from '../../../queries/communitySettings'
import {useAuth} from '../../../modules/auth'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(50, 'Community name cannot exceed 50 characters')
    .required('Community name is required'),
  description: Yup.string()
    .max(150, 'Community description cannot exceed 150 characters')
    .required('Community description is required'),
})

function CommunitySettingForm() {
  const {selectedCommunity, setSelectedCommunity} = useAuth()
  const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL

  const [previewCover, setPreviewCover] = useState<string>(
    selectedCommunity?.coverPic
      ? `${s3BaseUrl}${selectedCommunity?.coverPic}`
      : '/media/avatars/blank.png'
  )
  const [previewIcon, setPreviewIcon] = useState<string>(
    selectedCommunity?.iconPic
      ? `${s3BaseUrl}${selectedCommunity?.iconPic}`
      : '/media/avatars/blank.png'
  )

  const [selectedCover, setSelectedCover] = useState<File | null>(null)
  const [selectedIcon, setSelectedIcon] = useState<File | null>(null)

  const iconInputRef = useRef<HTMLInputElement | null>(null)
  const coverInputRef = useRef<HTMLInputElement | null>(null)

  const {mutateAsync: updateCommunities, isPending} = useUpdateCommunities()

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFile: React.Dispatch<React.SetStateAction<File | null>>,
    setPreview: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const file = event.target.files ? event.target.files[0] : null
    setFile(file)
    if (file) {
      setPreview(URL.createObjectURL(file))
    } else {
      setPreview('/media/avatars/blank.png')
    }
  }

  const handleRemoveIcon = () => {
    setPreviewIcon('/media/avatars/blank.png')
    setSelectedIcon(null)
  }

  const handleRemoveCover = () => {
    setPreviewCover('/media/avatars/blank.png')
    setSelectedCover(null)
  }

  return (
    <Formik
      initialValues={{
        name: selectedCommunity?.name || '',
        description: selectedCommunity?.description || '',
        removeCoverPic: undefined,
        removeIconPic: undefined,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, {setSubmitting}) => {
        const formData: any = new FormData()
        formData.append('name', values.name)
        formData.append('description', values.description)
        if (selectedCover) {
          formData.append('coverPic', selectedCover)
        } else if (values.removeCoverPic) {
          formData.append('removeCoverPic', values.removeCoverPic)
        }
        if (selectedIcon) {
          formData.append('iconPic', selectedIcon)
        } else if (values.removeIconPic) {
          formData.append('removeIconPic', values.removeIconPic)
        }

        try {
          const {data} = await updateCommunities({
            updateCommunity: formData,
          })
          setSubmitting(false)
          setSelectedIcon(null)
          setSelectedCover(null)
          const newCommunity: any = {
            ...selectedCommunity,
            name: data.name,
            description: data.description,
            coverPic: data.coverPic,
            iconPic: data.iconPic,
          }
          setSelectedCommunity(newCommunity)
        } catch (error) {
          console.error('Error updating community:', error)
        }
      }}
    >
      {({values, setFieldValue}) => (
        <Form className='w-100 ml15'>
          <div className='d-flex'>
            <div className='d-flex'>
              <div className='d-flex position-relative'>
                <div className='cursor-pointer position-relative'>
                  <img
                    src={previewIcon}
                    className='rounded'
                    alt='Icon Preview'
                    style={{
                      width: '72px',
                      height: '72px',
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                  />
                  {previewIcon !== '/media/avatars/blank.png' && (
                    <i
                      className='fa-solid fs-3 fa-xmark position-absolute top-0 start-100 translate-middle bg-white text-primary p-1 rounded-circle'
                      onClick={() => {
                        setFieldValue('removeIconPic', 'true')
                        handleRemoveIcon()
                      }}
                    ></i>
                  )}
                </div>
              </div>
              <div className='mx-6'>
                <div>
                  <h5 className='fw-bold'>Icon</h5>
                  <p className='text-gray'>
                    Recommended <br />
                    128 x 128 px
                  </p>
                </div>
                <Button
                  type='button'
                  variant='secondary'
                  onClick={() => iconInputRef.current?.click()}
                >
                  Change
                </Button>
                <input
                  type='file'
                  accept='image/*'
                  ref={iconInputRef}
                  style={{display: 'none'}}
                  onChange={(event) => handleFileChange(event, setSelectedIcon, setPreviewIcon)}
                />
              </div>
            </div>
            <div className='d-flex ml15'>
              <div className='d-flex position-relative'>
                <div className='cursor-pointer position-relative'>
                  <img
                    src={previewCover}
                    className='rounded'
                    alt='Cover Preview'
                    style={{
                      width: '271px',
                      height: '144px',
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                  />
                  {previewCover !== '/media/avatars/blank.png' && (
                    <i
                      className='fa-solid fs-3 fa-xmark position-absolute top-0 start-100 translate-middle bg-white text-primary p-1 rounded-circle'
                      onClick={() => {
                        setFieldValue('removeCoverPic', 'true')
                        handleRemoveCover()
                      }}
                    ></i>
                  )}
                </div>
              </div>
              <div className='mx-6'>
                <div>
                  <h5 className='fw-bold'>Cover</h5>
                  <p className='text-gray'>
                    Recommended <br />
                    1084 x 576 px
                  </p>
                </div>
                <Button
                  type='button'
                  variant='secondary'
                  onClick={() => coverInputRef.current?.click()}
                >
                  Change
                </Button>
                <input
                  type='file'
                  accept='image/*'
                  ref={coverInputRef}
                  style={{display: 'none'}}
                  onChange={(event) => handleFileChange(event, setSelectedCover, setPreviewCover)}
                />
              </div>
            </div>
          </div>
          <div className='col-lg-6 fv-row w-100'>
            <label htmlFor='name' className='required col-form-label fw-bold fs-6'>
              Community Name
            </label>
            <Field
              type='text'
              id='name'
              name='name'
              className='form-control form-control-lg form-control-solid'
              placeholder='Community Name'
            />
            <ErrorMessage name='name' component='div' className='text-danger' />
            <p className='text-muted float-end'>{values.name?.length}/50 characters</p>
          </div>
          <div className='col-lg-6 fv-row w-100 mt-5'>
            <label htmlFor='description' className='required col-form-label fw-bold fs-6'>
              Community Description
            </label>
            <Field
              as='textarea'
              id='description'
              name='description'
              className='form-control form-control-lg form-control-solid'
              placeholder='Community Description'
              rows='5'
            />
            <ErrorMessage name='description' component='div' className='text-danger' />
            <p className='text-muted float-end mb-5'>{values.description?.length}/150 characters</p>
          </div>
          <div className='mt-9 mb-9 d-flex flex-row-reverse w-100'>
            <Button type='submit' variant='primary' disabled={isPending}>
              {isPending ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                'Submit Your Settings'
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default CommunitySettingForm

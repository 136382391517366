import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query'

import {TEAM_MEMBER_QUERY_KEY} from '../constants/reactQueryKeys'
import * as teamApis from '../apis/userTeam'
import {parseError} from '../modules/common/utils'
import {toast} from 'react-toastify'

export const useGetTeamMembers = () => {
  return useQuery({
    queryKey: [TEAM_MEMBER_QUERY_KEY],
    queryFn: () => teamApis.getTeamMembers(),
  })
}

export const useInviteTeamMember = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (inviteData: any) => teamApis.inviteTeamMember(inviteData),
    onSuccess: () => {
      toast.success('Team member invited successfully')
      queryClient.invalidateQueries({queryKey: [TEAM_MEMBER_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useResendInvite = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (inviteId: number) => teamApis.resendInvite(inviteId),
    onSuccess: () => {
      toast.success('Team member re-invited successfully')
      queryClient.invalidateQueries({queryKey: [TEAM_MEMBER_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useDeleteTeamMember = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (inviteId: any) => teamApis.deleteTeamMember(inviteId),
    onSuccess: () => {
      toast.success('Team member deleted successfully')
      queryClient.invalidateQueries({queryKey: [TEAM_MEMBER_QUERY_KEY]})
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

import {useState} from 'react'

import FeedCommentsForm from './FeedCommentsForm'
import {useAuth} from '../../../modules/auth'
import FetchingSpinner from '../../../modules/common/FetchingSpinner'
import {useGetFeedComments} from '../../../queries/feedComments'
import CommentRow from './CommentRow'

const FeedComments = ({feedId, commentsEnabled}: {feedId: number; commentsEnabled: boolean}) => {
  const [replyOpenIndex, setReplyOpenIndex] = useState<number | string | null>(null)
  const commentsResult = useGetFeedComments(feedId)

  const {currentUser} = useAuth()

  const comments = commentsResult.data?.comments

  const toggleReplyField = (index: number) => {
    setReplyOpenIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  return (
    <div>
      <hr />
      <div className='comments-section mt-4'>
        {comments?.length < 1 && 'Be the first to comment!'}
        {commentsResult.isLoading && <FetchingSpinner />}
        {comments?.map((comment: any, index: any) => {
          return (
            <div key={index}>
              <CommentRow
                comment={comment}
                index={`${index}${comment.id}`}
                key={`${index}${comment.id}`}
                currentUserId={currentUser?.id}
                toggleReplyField={toggleReplyField}
                commentsEnabled={commentsEnabled}
              />
              {comment.replies?.map((comment: any, index: any) => (
                <CommentRow
                  comment={comment}
                  key={`${index}${comment.id}`}
                  index={`${index}${comment.id}`}
                  isReply={true}
                  currentUserId={currentUser?.id}
                  toggleReplyField={toggleReplyField}
                  commentsEnabled={commentsEnabled}
                />
              ))}
              {commentsEnabled && replyOpenIndex === `${index}${comment.id}` && (
                <FeedCommentsForm
                  feedId={feedId}
                  commentsLength={comments.length}
                  parentComment={comment}
                  toggleReplyField={() => toggleReplyField(index)}
                />
              )}
            </div>
          )
        })}
      </div>
      {commentsEnabled && <FeedCommentsForm feedId={feedId} commentsLength={comments?.length} />}
      {!commentsEnabled && (
        <div>
          <p className='text-muted fw-bold mt-8'>Comments are turned off for this feed</p>
        </div>
      )}
    </div>
  )
}

export default FeedComments

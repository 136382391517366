import * as Yup from 'yup'
import {toast} from 'react-toastify'
import {Link} from 'react-router-dom'
import {useState} from 'react'
import {useFormik} from 'formik'
import {Button, Modal} from 'react-bootstrap'

import {
  IPricePlanForm,
  pricePlanFormInitValues as initialValues,
} from '../../modules/accounts/components/settings/SettingsModel'
import apiClient from '../../apis/apiClient'

import './PricingIndex.css'

const pricingPlanFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required').max(150),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().required('Email is required').email('Email is invalid'),
})

export default function PricingIndex() {
  const [loading, setLoading] = useState(false)
  const [packageType, setPackageType] = useState('basic')
  const [showModal, setShowModal] = useState(false)

  const formik = useFormik<IPricePlanForm>({
    initialValues,
    validationSchema: pricingPlanFormSchema,
    onSubmit: async (values) => {
      setLoading(true)
      apiClient
        .post('/public/create-checkout-session', {...values, communityId: 1, packageType})
        .then((res) => {
          const {data} = res
          const {redirectUrl} = data.data
          window.location.href = redirectUrl
        })
        .catch(function (error) {
          toast.error(
            error ? error.response?.data?.message : 'Something went wrong, Please try later',
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          )
          setLoading(false)
        })
    },
  })

  return (
    <div className='py-12 bg-light h-100'>
      <div className='text-center py-7'>
        <h1 style={{fontSize: '25px'}} className='card-heading text-primary'>
          Join the GovAcha Community
        </h1>
        <h5 className='text-black'>
          Unlock your full potential in government contracting with the right tools and community
          support.
        </h5>
      </div>
      <div className='text-center'>
        <Link
          to='/auth'
          style={{fontWeight: 600}}
          className='text-info cursor-pointer text-decoration-underline'
        >
          Already have an account?
        </Link>
      </div>

      <div className='container py-5'>
        <div className='row justify-content-center'>
          <div className='col-md-4 col-sm-6 mb-4'>
            <div className='card basic-pricing-card mt-20 shadow-sm border-rounded'>
              <div className='card-body p-6'>
                <div className='d-flex align-items-center mb-3'>
                  <p className='fs-1 text-primary fw-bold mb-0'>GovAcha Basic</p>
                  <i className='fa-solid fs-2 mx-3 fa-shield-halved text-primary'></i>
                </div>
                <p
                  className='mb-4 fs-7'
                  style={{color: 'hsl(var(--foreground-light) / var(--tw-text-opacity))'}}
                >
                  {' '}
                  Start your journey with the essentials, perfect for small businesses and
                  newcomers.
                </p>
                <div>
                  <Button
                    type='button'
                    variant='outline-primary'
                    className='mt-5 btn btn-outline w-100'
                    onClick={() => {
                      setPackageType('basic')
                      setShowModal(true)
                    }}
                  >
                    Join Basic Plan
                  </Button>
                </div>
                <div className='mt-5 d-flex flex-row'>
                  <h1 className='card-subtitle text-primary fw-bold' style={{fontSize: '4rem'}}>
                    $99
                  </h1>
                  <span className='text-primary fw-bold mt-10'>/ month</span>
                </div>
                <div className='d-flex flex-column mt-10'>
                  <ul className='list-unstyled mb-4'>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Community Engagement</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Members Directory</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Essential Resources</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Classroom Access</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Event Calendar</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Personal Notes</span>
                    </li>
                    <li className='d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Leaderboard</span>
                    </li>
                  </ul>
                </div>
                <div className='text-center mt-5'>
                  <i className='text-primary'>Join GovAcha Basic – Get Started</i>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-4 col-sm-6 mb-4'>
            <div className='card border border border-primary mt-7 shadow-sm border-rounded'>
              <div className='bg-primary w-100 px-3 py-4 text-center'>
                <p className='fw-bolder fs-3 text-white m-0'>Top Choice</p>
              </div>
              <div className='card-body p-6'>
                <div className='d-flex align-items-center mb-3'>
                  <p className='fs-1 text-primary fw-bold mb-0'>GovAcha Premium</p>
                  <i className='fa-solid fs-2 mx-3 fa-crown text-primary'></i>
                </div>
                <p
                  className='mb-4 fs-7'
                  style={{color: 'hsl(var(--foreground-light) / var(--tw-text-opacity))'}}
                >
                  {' '}
                  Empower your business with advanced tools, resources, and exclusive community
                  access.
                </p>
                <div>
                  <Button
                    type='button'
                    variant='primary'
                    className='w-100'
                    onClick={() => {
                      setPackageType('premium')
                      setShowModal(true)
                    }}
                  >
                    Join Premium Plan
                  </Button>
                </div>
                <div className='mt-5 d-flex flex-row'>
                  <h1 className='card-subtitle text-primary fw-bold' style={{fontSize: '4rem'}}>
                    $297
                  </h1>
                  <span className='text-primary fw-bold mt-10'>/ month</span>
                </div>
                <div className='d-flex flex-column mt-10'>
                  <ul className='list-unstyled mb-4'>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>GovAcha Basic Access</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>GovChat</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Pipelines</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Advanced Resources</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Premium Courses</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Priority Support</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Expert Consultation</span>
                    </li>
                    <li className='mb-6 d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Private Feeds</span>
                    </li>
                    <li className='d-flex align-items-center'>
                      <i className='fas fa-check-circle text-primary me-3 fs-4'></i>
                      <span className='fs-6 fw-medium'>Private Community Access</span>
                    </li>
                  </ul>
                </div>
                <div className='text-center mt-5'>
                  <i className='text-primary'>Join GovAcha Premium – Unlock Advanced Features</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)} centered backdrop='static'>
          <Modal.Header closeButton>
            <Modal.Title>Please fill the form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formik.handleSubmit} noValidate>
              <div className='text-center'>
                <div className='my-5 d-flex align-items-end'>
                  <div className='d-block w-100 mx-1'>
                    <div className='text-start'>
                      <label className='fw-bold mx-2 text-gray' htmlFor='fullName'>
                        First Name
                      </label>
                    </div>

                    <input
                      type='text'
                      style={{backgroundColor: '#F1F1F2'}}
                      className='p-3 border-0 m-2 rounded-3 w-100'
                      placeholder='First name'
                      {...formik.getFieldProps('firstName')}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <div className='text-start mx-3 fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.firstName}</div>
                      </div>
                    )}
                  </div>
                  <div className='d-block w-100 mx-1'>
                    <div className='text-start'>
                      <label className='fw-bold mx-2 text-gray' htmlFor='fullName'>
                        Last Name
                      </label>
                    </div>
                    <input
                      type='text'
                      style={{backgroundColor: '#F1F1F2'}}
                      className='p-3 border-0 m-2 rounded-3 w-100'
                      placeholder='Second name'
                      {...formik.getFieldProps('lastName')}
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <div className='text-start mx-3 fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.lastName}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='pl-3'>
                  <div style={{textAlign: 'start'}}>
                    <label htmlFor='email' className='email-label fw-bold text-gray'>
                      Email
                    </label>
                  </div>

                  <input
                    type='text'
                    style={{backgroundColor: '#F1F1F2'}}
                    className='p-3 border-0 my-2 w-100 rounded-3'
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                  />
                </div>
                {formik.touched.email && formik.errors.email && (
                  <div className='text-start mx-3 email-validator fv-plugins-message-container'>
                    <div className='email-validator fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
                <div className='my-5'>
                  <button type='submit' className='btn mb-2 btn-primary'>
                    {!loading && 'Start your free trial'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <div>
                    <small className='text-muted fw-bold mt-6'>
                      Try any Later plan free for 7 days.
                    </small>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

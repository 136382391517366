import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {toast} from 'react-toastify'

import {EVENTS_QUERY_KEY} from '../constants/reactQueryKeys'
import * as eventsApi from '../apis/events'
import {parseError} from '../modules/common/utils'

export const useFetchEvents = (startDate: string, endDate: string, limit?: number) => {
  return useQuery({
    queryKey: [EVENTS_QUERY_KEY, startDate, endDate],
    queryFn: () => eventsApi.fetchEvents({page: 1, limit: limit || 1000, startDate, endDate}),
    refetchOnWindowFocus: false,
  })
}

export const useCreateSingleEvent = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (eventData: any) => eventsApi.postEvent(eventData),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [EVENTS_QUERY_KEY]})
      toast.success('Event created successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useDeleteEvent = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (eventId: number) => eventsApi.deleteEvent(eventId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [EVENTS_QUERY_KEY]})
      toast.success('Event deleted successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useDeleteEventSeries = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({eventId, eventData}: {eventId: any; eventData: any}) =>
      eventsApi.deleteEventSeries(eventId, eventData),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [EVENTS_QUERY_KEY]})
      toast.success('Event series deleted successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useUpdateEvent = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({eventId, eventData}: any) => eventsApi.putEvent(eventId, eventData),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [EVENTS_QUERY_KEY]})
      toast.success('Event updated successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}

export const useUpdateSeries = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({eventId, eventData}: any) => eventsApi.putEventSeries(eventId, eventData),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [EVENTS_QUERY_KEY]})
      toast.success('Event updated successfully')
    },
    onError: (error: any) => {
      toast.error(parseError(error))
    },
  })
}
